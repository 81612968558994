import { useAcaoContext } from "../../contexts/acaoContext";
import { Container } from "./styles";
import imgAcessoNegado from "../../assets/img/acessoNegado.svg";
import LogoItauPersonnalite from "../../assets/img/itau.svg";
import LogoItauUniclass from "../../assets/img/LogoItau.svg";
import Footer from "../../components/footer";

export function AcessoNegadoItau() {
  const width = window.screen.width;
  const { configuracao } = useAcaoContext();

  const actionKey = configuracao?.key || "";

  let color, img = null;
  color = configuracao?.background || "#000000";

  if (actionKey.includes("uniclass")) {
    img = LogoItauUniclass;
  } else if (actionKey.includes("personnalite")) {
    img = LogoItauPersonnalite;
  }

  return (
    <>
      <Container color={color}>
        <div className="content">
          <div className="container-img">
            <img src={imgAcessoNegado} alt="acesso negado" />
          </div>
          <div className="container-text">
            <h1>Olá!</h1>
            <p>
              O acesso ao seu benefício<br /> do Minhas Vantagens é<br /> exclusivo por meio do<br /> aplicativo do Itaú.
            </p>
          </div>
        </div>

        <div className="logo">
          {img && <img src={img} alt="Logo" />}
        </div>
      </Container>
      {width >= 480 ? <Footer /> : null}
    </>
  );
}
