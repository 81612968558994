import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 327px));
  grid-gap: 16px;
  justify-content: center;
  button {
    border-radius: 100px;
    background: #e86972;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:disabled {
      cursor: not-allowed;
      background: #e86972;
      opacity: 0.8;
    }
  }

  @media (max-width: 1211px) {
    grid-template-columns: 1fr;
  }
`;
