import React, { useRef, useEffect } from "react";
import Quagga from "quagga";

const BarcodeScanner = ({ onDetected }) => {
  const scannerRef = useRef();

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: scannerRef.current,
          constraints: {
            width: 275,
            height: 275,
            facingMode: "environment", // use 'user' for front camera
          },
        },
        decoder: {
          readers: ["code_128_reader"], // you can add more readers as needed
        },
      },
      (err) => {
        if (err) {
          alert("Erro ao ler código de barras");
          alert(err);
          console.error(err);
          return;
        }
        Quagga.start();

        // Optionally, you can listen for detection events
        Quagga.onDetected((result) => {
          onDetected(result.codeResult.code);
        });
      }
    );

    return () => {
      Quagga.stop();
    };
  }, [onDetected]);

  return (
    <>
      <div ref={scannerRef} style={{ width: "100%", height: "100%" }}>
        <video playsinline autoplay></video>
      </div>
    </>
  );
};

export function BarCodeScan() {
  const [data, setData] = React.useState("Not Found");

  return (
    <>
      <BarcodeScanner
        width={300}
        height={300}
        onUpdate={(err, result) => {
          if (result) setData(result.text);
          else setData("Not Found");
        }}
      />
      <p>{data}</p>
    </>
  );
}

export default BarcodeScanner;
