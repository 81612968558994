import { useState } from "react";
// import Faker from "faker";
import React, { createContext, useContext } from "react";
import { api } from "../services/api";
import { toast } from "react-toastify";

const Context = createContext({});

// const mockRegister = {
//   // invoice_id: 22,
//   // done: true,
//   // error: false,
//   // message: "Nota não existe.",
// };

export function UseBarCodeProvider(props) {
  const { children } = props;
  const [state, setState] = useState({});

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function checkQrCode(str, callback) {
    const match = str.match(/\d{44}/);

    if (match) {
      const code = match[0];
      // eslint-disable-next-line no-unused-vars
      const [__, uf, aamm, cnpj, _, type] = code.match(
        /([0-9]{2})([0-9]{2})([0-9]{14})([0-9]{2})([0-9]{2})([0-9].*)/
      );

      await timeout(2000);
      const response = await api.post(`client/action/invoice/register`, {
        uf,
        type,
        access_key: code,
      });

      setState(response);
      callback();
      if (response.invoice_id) {
        const statusResponse = await statusCode(response);
        setState(statusResponse);
        await loopStatusCode(response);
      }
    } else {
      toast.error("QR Code inválido");
    }
  }

  async function loopStatusCode(code) {
    while (code.invoice_id) {
      await timeout(15000);
      const response = await statusCode(code);
      setState(response);
      if (response.done) {
        break;
      }
    }
  }

  async function statusCode(code) {
    try {
      const response = await api.get(
        `client/action/invoice/status/${code.invoice_id}`
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Context.Provider value={{ checkQrCode, state, setState }}>
      {children}
    </Context.Provider>
  );
}

export function useBarCode() {
  const context = useContext(Context);
  return context;
}
