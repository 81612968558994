import styled from "styled-components";

export const Container = styled.div`
  padding-top: 12px;
  padding-bottom: 98px;
  .container-img {
    text-align: center;
    margin-bottom: 16px;
  }

  h2 {
    color: #e86972;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
    margin-bottom: 32px;
  }

  ul {
    padding: 0px;
    margin-bottom: 26px;
    li {
      width: 250px;
      display: flex;
      align-items: start;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
      img {
        padding-top: 4px;
      }
      span {
        color: #333;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: block;
        margin-left: 23px;
      }
    }
  }

  button {
    border-radius: 100px;
    background: #e86972;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
