import styled from "styled-components";

const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Opera Mini|IEMobile|Mobile/i.test(
    navigator.userAgent
  );
};

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  z-index: 20;
  background: ${({ backgroundColor }) =>
    backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};

  // CSS layout STONE
  * {
    color: ${({ isStone }) => isStone && "#000"} !important;
  }
  input {
    border: ${({ isStone }) => isStone && "1px solid"} !important;
  }

  .logo-provisorio {
    height: 244px;
    padding-top: 28px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    min-height: calc(100vh - 84px);
    justify-items: center;
    .container-logo-promo {
      overflow: hidden;
      margin-top: 73px;
      width: 100%;
      .container-swiper {
        overflow: hidden;
      }
      img {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: block;
      }
      img {
        object-fit: contain;
      }
      .logos {
        display: none;
      }
    }

    .form {
      margin: 73px 0;
      width: 383px;
      .parcerias {
        display: none;
      }
      &.sistema {
        margin: 73px 0;
        align-self: center;
        h3 {
          margin: 30px 0;
        }
        button {
          margin-top: 30px;
        }
      }
      .logos {
        display: flex;
        justify-content: center;
        width: 100%;
        grid-gap: 40px;
        margin-bottom: 37px;
        img {
          object-fit: contain;
          width: 200px;
          height: 100px;
        }
      }

      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #ffffff;
        display: block;
        &.personalizado {
          font-size: 12px;
          font-style: italic;
          line-height: inherit;
          margin-top: 20px !important;
        }
      }
      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: #ffffff;
        margin: 0;
      }

      small {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        display: block;
        margin-bottom: 14px;
        &.esqueci-senha {
          position: relative;
          bottom: -30px;
          cursor: pointer;
        }
      }
      .mais-info {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #ffffff;
        a {
          text-decoration: underline;
        }
      }
      .form-login {
        margin-top: 19px;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 45px;
        h4 {
          font-weight: bold;
          font-size: 18px;
          line-height: 160%;
          color: #ffffff;
          text-align: center;
        }
        .bnts-envio {
          .div-btn {
            background: #ffffff;
            border-radius: 600px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 10px;
            cursor: pointer;
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              text-align: center;
              color: ${({ backgroundColor }) => backgroundColor || "#793794"};
            }
          }
        }
        .campoCelular {
          background: ${({ backgroundColor }) => backgroundColor || "#793794"};
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 600px;
          font-weight: 600;
          font-size: 20px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.04em;
          color: #ffffff;
          width: 80%;
          padding: 10px;
          margin: 20px auto;
          display: block;
          text-align: center;
        }
        .box-checkbox {
          display: flex;
          margin-bottom: 10px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #ffffff;
            text-align: initial;
            p {
              margin-top: 0;
              a,
              strong {
                font-family: "Poppins-Bold" !important;
              }
            }
          }
        }
        .atencao {
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 10px 10px;
          padding-bottom: 20px;
          margin: 34px 0;
          position: relative;
          h5 {
            font-weight: bold;
            font-size: 18px;
            line-height: 160%;
            color: #ffffff;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            border-radius: 100px;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            background: ${({ backgroundColor }) =>
              backgroundColor || "#6f3e98"};
            padding: 10px 40px;
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 130%;
            text-align: center;
            color: #ffffff;
          }
        }
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="tel"] {
          background-color: #ffffff;
          border-radius: 100px;
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.5);
          border: none;
          margin-bottom: 13px;
          padding: 0 20px;
          text-transform: uppercase;
          text-align: center;
          &:disabled {
            background-color: #bcbcbc;
          }
        }

        .faleConosco-sms {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 40px;
          .sac {
            margin: 0;
            span {
              font-size: 15px !important;
              color: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
            }
          }
          img {
            cursor: pointer;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
      }
    }

    .sac {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      box-shadow: 0px 20px 80px -40px rgba(220, 46, 97, 0.6);
      border-radius: 600px;
      cursor: pointer;
      height: 47px;
      padding: 0 20px 0 2px;
      span {
        font-weight: bold;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      }
      svg {
        fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
        rect {
          fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
        }
      }
    }

    &.itau {
      font-family: "Itau Text Pro Regular";

      .form {
        .logos {
          justify-content: left;
          img {
            width: 100px;
            margin-left: 10px;
          }
        }
        .parcerias {
          display: block;
          font-size: 12px;
          margin-top: -40px;
          margin-bottom: 40px;
          text-align: left;
          margin-left: 22px;
        }
        .titulo {
          font-style: normal;
          font-weight: 800;
          font-size: 21.0571px;
          line-height: 27px;
          color: #ffffff;
          text-align: left;
          margin-left: 22px;
          display: flex;
          grid-gap: 5px;
          margin-bottom: 40px;
          font-family: "Itau Text Pro X Bold";
          .titulo-text {
            font-weight: 500;
            font-family: "Itau Text Pro Regular";
          }
        }
        .subtitulo {
          font-style: normal;
          font-weight: 400;
          font-size: 25.0746px;
          line-height: 32px;
          color: #ffffff;
          text-align: left;
          margin-left: 32px;
        }
      }
    }
  }

  @media (max-width: 1211px) {
    .container {
      max-width: 350px;
      width: 90%;
      grid-template-columns: 1fr;
      .container-logo-promo {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 227px 1fr;
        align-items: center;
        img {
          width: 90%;
          height: 100%;
          margin: 0 auto;
          display: block;
        }
        .logos {
          display: flex;
          flex-direction: column;
          grid-gap: 20px;
          img {
            width: 100%;
            max-width: 80px;
          }
        }
      }
      .form {
        width: 100%;
        margin: 0;
        &.sistema {
          margin-top: 30px;
        }
        .logos {
          display: none;
          img {
            width: 150px;
            height: auto;
          }
        }
        span {
          font-size: 12px;
          &.personalizado {
            font-size: 9px;
            font-style: italic;
            line-height: inherit;
            width: 270px;
            margin: 0 auto;
          }
        }
        h3 {
          font-size: 17px;
        }
        .form-login {
          width: 90%;
          max-width: 350px;
          margin-left: auto;
          margin-right: auto;
          border: none;
          input[type="text"],
          input[type="password"],
          input[type="email"],
          input[type="tel"] {
            font-size: 16px;
            height: 34px;
          }
          .faleConosco-sms {
            flex-direction: column;
            grid-gap: 20px;
          }
        }
      }
      .sac {
        height: auto;
        span {
          font-size: 11px !important;
        }
        svg {
          height: 26px;
          width: 26px;
        }
      }
      .principal {
        span {
          font-size: 12px !important;
        }
        svg {
          height: 36px;
          width: 36px;
        }
      }
      &.itau {
        .form {
          .parcerias {
            display: block;
            text-align: left;
            margin-top: -20px;
            margin-bottom: 20px;
          }
          .titulo {
            .titulo-text {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
`;

export const ClickAjuda = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  margin: 19px 0;
  cursor: ${(props) => (props.disablePointer ? "default" : "pointer")};
  user-select: none;

  span {
    font-weight: ${({ isStone }) => (isStone ? "700" : "900")} !important;
    font-size: 12px !important;
    text-align: center;
    color: #f3faff;
    color: ${({ isStone }) => isStone ? "#2B323B" : ""} !important;
    line-height: ${({ isStone }) => isStone ? "16.8px" : ""} !important;
    color: ${({ isStone }) => isStone ? "#2B323B" : ""} !important;
    font-size: ${({ isStone }) => isStone ? "12px" : ""} !important;
    display: -webkit-box !important;

    &.clique {
      margin: 0 3px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 1211px) {
    border-top: ${({ isStone }) =>
      isStone ? "1px solid #000" : "1px solid #ffffff"} !important;
    padding-top: 20px;
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: ${({ isStone }) =>
      isStone ? "25px" : ""} !important;
    img {
      width: 17px;
    }
  }
`;
