import styled from "styled-components";

export const ContainerLogoff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  img {
    margin-top: 100px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: center;
  }
`;
