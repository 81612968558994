import ImgRelogio from "../../../assets/img/relogio-qr-code.svg";
import { useBarCode } from "../../../hooks/checkBarCode";
import { useModal } from "../../../hooks/modaisBarCode";
import { Loader } from "../../Loader";
import { Container } from "./styles";

export function Processando(props) {
  const { message } = props;
  const { handleClose } = useModal();
  const { state } = useBarCode();

  return (
    <Container>
      <div className="container-img">
        <img src={ImgRelogio} alt="" />
      </div>

      <h3>Recebemos sua nota!</h3>

      {message ? <p>{message}</p> : <></>}

      {state.done ? (
        <button onClick={handleClose}>Ok, entendi.</button>
      ) : (
        <div className="loader">
          <Loader color="#e86972" />
        </div>
      )}
    </Container>
  );
}
