import { useNavigate, useParams } from "react-router-dom";
import { Container } from "./styles";
import imgFechar from "../../../assets/img/fecha-preto.svg";
import imgCard from "../../../assets/img/card-novidade.svg";
import imgBanner from "../../../assets/img/banner-novidade.png";
import Button from "../../button";

export function ModalNovidade(props) {
  const { handleClose } = props;
  const navigate = useNavigate();
  const { url } = useParams();

  return (
    <Container>
      <button onClick={handleClose} className="btn-fechar">
        <img src={imgFechar} alt="Fechar" />
      </button>
      <div className="container-modal">
        <div className="container-title">
          <h3>Novidade!</h3>

          <div className="container-descricao">
            <span>Acesse</span>
            <div className="icon">
              <img src={imgCard} alt="card" />
            </div>

            <p>Meus Vouchers</p>
          </div>
        </div>

        <div className="container-banner-novidade">
          <img src={imgBanner} alt="banner novidade" />
        </div>

        <div className="container-detalhes">
          <p>
            para conferir seu histórico <br /> de experiências resgatadas.
          </p>
        </div>

        <div className="container-btns">
          <Button
            title="Acessar"
            type="submit"
            loading={false}
            disabled={false}
            onClick={() => {
              navigate(`/${url}/carteira`);
            }}
          />

          <button onClick={handleClose}>
            <p>Fechar</p>
          </button>
        </div>
      </div>
    </Container>
  );
}
