import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 1px);
  width: 100%;

  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  // CSS layout STONE
  * {
    color: ${({ isStone }) => isStone && "#000"} !important;
    font-family: ${({ isStone }) => isStone && "Inter"} !important;
  }

  .overlay-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${({ backgroundColor }) =>
      backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      background: ${({ backgroundColor }) =>
        `linear-gradient(270deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 40%;
      height: 100%;
      z-index: 9;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      background: ${({ backgroundColor }) =>
        `linear-gradient(90deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 40%;
      height: 100%;
      right: 0px;
      z-index: 9;
    }
  }
  .loader {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    position: relative;
  }
  .container {
    width: 1203px;
    position: relative;

    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      a {
        display: flex;
        grid-gap: 10px;
        span {
          font-family: "Montserrat-Regular";
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
        }
      }
      .verResgatesCupom {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 10px;
        img {
          height: 17px;
        }
        p {
          margin: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration-line: underline;
          cursor: pointer;
          color: #ffffff;
          font-family: "Montserrat-Regular";
        }
      }
    }
    .logos {
      grid-gap: 40px;
      display: flex;
      align-items: baseline;
      margin-bottom: 40px;
      padding-top: 40px;
      img {
        object-fit: contain;
        width: 200px;
        height: 100px;
      }
      .logo {
        width: 230px;
      }
      .fecha-tela {
        width: 14px;
        height: 14px;
        margin-top: 20px;
        margin-bottom: 40px;
        margin-left: 10px;
      }
    }
  }
  .conteudo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin: 100px auto;
    margin-bottom: auto;
    .text-meus-resgates {
      display: none;
    }
    .escolherMais {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      border-radius: 100px;
      border: 1px solid #f17e21;
      width: 290px;
      padding: 5px 0;
      margin: 0 auto;
      p {
        color: #f17e21;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        cursor: pointer;
      }
    }
    &.remove {
      grid-template-columns: 1fr;
      margin-bottom: auto;
      margin-top: 0;
      gap: 0;
      .text-meus-resgates {
        display: block;
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
        z-index: 20;
        h1 {
          font-weight: 700;
          font-size: 24px;
          color: #ffffff;
          margin-bottom: 0;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          margin-top: 0;
        }
      }
      .premio {
        margin-top: ${({ isStone }) => isStone && "40px"};
        margin-right: ${({ isStone }) => isStone && "0"};
        grid-gap: ${({ isStone }) => isStone && "20px"};
        button {
          order: ${({ isStone }) => (isStone ? "inherit" : 1)};
        }
        img {
          order: ${({ isStone }) => (isStone ? "inherit" : 2)};
        }
        h4 {
          order: ${({ isStone }) => (isStone ? "inherit" : 3)};          
        }
      }
    }
    &.slide-container {
      width: 90%;
      .swiper {
        width: 100%;
        height: fit-content;
        .swiper-button-prev {
          left: 20%;
        }
        .swiper-button-next {
          right: 20%;
        }
      }
    }
    .topo-titulo {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 398px;
      border-left: 1px solid #ffffff;
      padding-left: 100px;
      position: relative;
      z-index: 20;
      .atencao {
        display: flex;
        grid-gap: 10px;
        justify-content: center;
        background: ${({ backgroundColor }) =>
          `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box,
            linear-gradient(
                270.06deg,
                #efefef 0.05%,
                rgba(239, 239, 239, 0) 157%
              )
              border-box` ||
          `linear-gradient(#8b288c, #8b288c) padding-box,
            linear-gradient(
                270.06deg,
                #efefef 0.05%,
                rgba(239, 239, 239, 0) 157%
              )
              border-box`};
        border-radius: 8px;
        border: 4px solid transparent;
        margin-bottom: 20px;
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: #ffffff;
          margin: 8px 0;
        }
      }
      .text {
        h1 {
          font-weight: 700;
          font-size: 60px;
          line-height: 60px;
          color: #fcb614;
          margin-bottom: 0;
        }
        p {
          font-weight: 400;
          font-size: 22px;
          line-height: 150%;
          color: #ffffff;
          margin-top: 0;
        }
      }

      button {
        width: 280px;
      }
    }
    .unicoResgate {
      display: flex;
      justify-content: center;
    }
    .swiper {
      width: 1200px;
      position: relative;
      overflow: hidden;
      padding: 30px 0px;
      margin-bottom: 40px;
      .swiper-wrapper {
        display: flex;

        &.disabled {
          transform: translate3d(0px, 0, 0) !important;
          justify-content: center;
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        color: white;
      }
      .swiper-slide {
        opacity: 0.2;
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 0.8;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
    .premio {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      width: 300px !important;
      margin-right: ${({ isStone }) => (isStone ? "0px" : "10px")} !important;
      img {
        width: ${({ isStone }) => (isStone ? "240px" : "259px")} !important;
        margin-bottom: ${({ isStone }) => (isStone ? "20px" : "0px")} !important;
        height: auto;
      }
      h4 {
        font-weight: 900;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #fff;
        margin-bottom: 20px;
        margin-top: 0;        
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        margin: 10px 0 50px;
        &.resgate-num {
          font-weight: 800;
          font-size: 14px;
          line-height: 150%;
          margin: 0;
        }
      }
      &:hover {
        transform: scale(0.9);
        transition: all 0.5s;
      }
      button {
        width: ${({ isStone }) => (isStone ? "308px" : "260px")} !important;
        margin: 0 0 50px;
        margin-top: ${({ isStone }) => (isStone ? "44px" : "0")} !important;
      }
      .voucher-indisponivel {
        border: 1px solid #d5c8d3;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        width: 328px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 50px 20px;
        grid-gap: 20px;
        margin-top: 20px;
        img {
          padding: 0 !important;
        }
        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 160%;
          color: #ff004b;
          background: #ffffff;
          border-radius: 83px;
          display: block;
          position: absolute;
          top: -20px;
          width: 271px;
          height: 42px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: #ffffff;
          text-align: initial;
        }
      }
    }
  }

    ${({ isStone }) =>
    isStone &&
    `
    .overlay-modal {
      height: 100%;
      &::before,
      &::after {
        content: none;
      }
    }
    .slide-container {
      width: 100% !important;
      .swiper-pagination {
        position: initial;
      }
      .swiper-button-prev {
        left: 20% !important;
        top: 96%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-next {
        right: 20% !important;
        top: 96%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-slide {
        opacity: 0.2;
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 0.8 !important;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
    .container {
      width: 90%;
      margin-bottom: 0;
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
        .logo {
          width: 109px;
          margin-top: 0;
        }
      }
    }
    .conteudo {
      grid-template-columns: 1fr;
      margin: 0;
      margin-bottom: auto;
      grid-gap: 0;
      &.remove {
        margin: 0;
        margin-bottom: auto;
        .text-meus-resgates {
          width: 90%;
          h1 {
            width: 100%;
            font-size: 18px;
            line-height: 23px;
            margin-top: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .swiper {
        width: 100%;
      }
      .premio {
        img {
          width: ${({ isStone }) => (isStone ? "240px" : "259px")} !important;          
          /* height: 264px !important; */
        }
        h4 {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 20px;
          line-height: 22px;
        }
        button {
          margin: 0 0 50px;
          
        }
      }
      .topo-titulo {
        width: auto;
        padding-left: 0;
        border-left: none;
        text-align: center;
        h1 {
          font-size: 40px !important;
          line-height: 150% !important;
          margin: 0 !important;
        }
        p {
          font-size: 14px !important;
          line-height: 150% !important;
        }
        .atencao {
          width: 290px;
          margin-left: auto;
          margin-right: auto;
          p {
            font-size: 15px;
          }
        }
      }
    }
  `}

  @media (max-width: 1211px) {
    .overlay-modal {
      height: 100%;
      &::before,
      &::after {
        content: none;
      }
    }
    .slide-container {
      width: 100% !important;
      .swiper-pagination {
        position: initial;
      }
      .swiper-button-prev {
        left: 20% !important;
        top: 96%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-next {
        right: 20% !important;
        top: 96%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-slide {
        opacity: 0.2;
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 0.8 !important;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
    .container {
      width: 90%;
      margin-bottom: 0;
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
        .logo {
          width: 109px;
          margin-top: 0;
        }
      }
    }
    .conteudo {
      grid-template-columns: 1fr;
      margin: 0;
      margin-bottom: auto;
      grid-gap: 0;
      &.remove {
        margin: 0;
        margin-bottom: auto;
        .text-meus-resgates {
          width: 90%;
          h1 {
            width: 100%;
            font-size: 18px;
            line-height: 23px;
            margin-top: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .swiper {
        width: 100%;
      }
      .premio {
        img {        
          width: ${({ isStone }) => (isStone ? "240px" : "259px")} !important;
        }
        h4 {
          margin-bottom: 10px;
          font-weight: ${({ isStone }) => (isStone ? "700" : "900")};
          font-size: ${({ isStone }) => (isStone ? "20px" : "14px")};
          line-height: ${({ isStone }) => (isStone ? "22px" : "150%")};
        }
        button {
          margin: 0 0 50px;
          
        }
      }
      .topo-titulo {
        width: auto;
        padding-left: 0;
        border-left: none;
        text-align: center;
        h1 {
          font-size: 40px !important;
          line-height: 150% !important;
          margin: 0 !important;
        }
        p {
          font-size: 14px !important;
          line-height: 150% !important;
        }
        .atencao {
          width: 290px;
          margin-left: auto;
          margin-right: auto;
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
`;

export const LoadLink = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    width: 150px;
  }
  .erro {
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 30px;
      color: #6f3e98;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #6f3e98;
      margin-top: 10px;
    }
  }
`;

export const ProductNameSpan = styled.h4`
  all: unset; 
  color: ${({ color }) => color || "black"} !important;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;

`;


export const ProductNameSpanPrincipal = styled.h4`
  all: unset; 
  color: ${({ color }) => color || "black"} !important;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
`;


