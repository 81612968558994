import { Container } from "./styles";
import ImgErroQrCode from "../../../../assets/img/error-qr-code.svg";
import { Vouchers } from "./vouchers";
export function Card(props) {
  // console.log(props);

  function formatDate(date, divisior) {
    if (!date) return "";
    const dateFormated = date.toLocaleDateString("pt-BR");

    if (dateFormated === "Invalid Date") return `${date}`;

    const fullDate = dateFormated;
    const time = date.toLocaleTimeString("pt-BR");
    if (divisior) {
      const dateSplited = dateFormated.split("/");
      return `${dateSplited[0]}${divisior}${dateSplited[1]}${divisior}${dateSplited[2]} ${time}`;
    }
    return fullDate;
  }

  function getStateColor(number) {
    const colorState = {
      0: "processando",
      1: "processando",
      5: "processando",
      2: "atencao",
      3: "atencao",
      4: "atencao",
      6: "sucesso",
    };

    return colorState[number];
  }

  return (
    <Container>
      <div className="container-header description">
        <h4>Nº da Nota Fiscal</h4>
        <p>{props.access_key}</p>
      </div>

      <div className="container-data description">
        <h4>Data de Inclusão:</h4>
        <p>{formatDate(new Date(props.data_included), "/")}</p>
      </div>

      <div className={`container-status ${getStateColor(props.status_id)}`}>
        <h5>Status:</h5>
        <small>{props.status_text}</small>
      </div>

      {props.status_id === 4 ? (
        <div className="container-resultado erro">
          <img src={ImgErroQrCode} alt="" />
          <p>
            Não encontramos na sua nota os produtos participantes da promoção.
          </p>
        </div>
      ) : (
        props.vouchers.map((item) => {
          return <Vouchers key={item.id} {...item} />;
        })
      )}
    </Container>
  );
}
