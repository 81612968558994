import React, { createContext, useContext } from "react";
import { useState } from "react";
import ImgQrCode from "../../../assets/img/qrCode.svg";
// import ImgBarcode from "../../../assets/img/barCode.svg";
import ImgKey from "../../../assets/img/key.svg";

const Context = createContext({});

const itens = [
  {
    id: 1,
    title: `Ler ${"\n"} QR Code`,
    className: "qr-code",
    path: "/dashboard/qrcode",
    img: ImgQrCode,
    active: true,
    viewer: false,
    permission: false,
  },
  // {
  //   id: 2,
  //   // title: `Ler Código ${"\n"} de Barras`,
  //   title: `Em breve ${"\n"} `,
  //   className: "codigo_de_barras",
  //   path: "",
  //   img: ImgBarcode,
  //   active: false,
  //   viewer: false,
  //   permission: false,
  // },
  {
    id: 3,
    title: `Digitar Chave ${"\n"} da Nota`,
    className: "chave_da_nota",
    path: "/dashboard/key-code",
    img: ImgKey,
    active: true,
    viewer: false,
    permission: false,
  },
];

export function UseTabProvider(props) {
  const { children } = props;
  const [tabs, setTabs] = useState(itens);

  function updateTab(id) {
    const update = tabs.map((tab) => {
      if (tab.title === id) {
        return {
          ...tab,
          active: true,
          viewer: true,
          permission: true,
        };
      }

      return {
        ...tab,
        active: false,
        viewer: tab.viewer,
        permission: false,
      };
    });

    setTabs(update);
  }

  return (
    <Context.Provider value={{ tabs, updateTab }}>{children}</Context.Provider>
  );
}

export function useTab() {
  const context = useContext(Context);
  return context;
}
