import { Container } from "./styles";
import imgFechar from "../../../assets/img/fecha-preto.svg";
import ClockIcon from "../../../assets/img/clock-icon.svg";
import Button from "../../button";

export function CarregandoExperiencias(props) {
  const { handleClose } = props;

  return (
    <Container>
      <button onClick={handleClose} className="btn-fechar">
        <img src={imgFechar} alt="Fechar" />
      </button>
      <div className="container-modal">
        <img src={ClockIcon} alt="Clock Icon" />
        <div className="container-title">
          <h3>Estamos carregando seu histórico de experiências.</h3>
        </div>
        <div className="container-sub-title">
          <p>Em até 10 minutos ele estará disponível.</p>
        </div>
        <div className="container-btns">
          <Button
            title="Ok"
            type="submit"
            loading={false}
            disabled={false}
            onClick={handleClose}
          />
        </div>
      </div>
    </Container>
  );
}
