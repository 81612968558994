import React, { Fragment } from "react";
export function When(props) {
  const { expr, children } = props;
  // If the expression is a function, and it returns false, return a fragment
  if (typeof expr === "function" && !expr()) return <Fragment />;

  // If the expression is false, return a fragment
  if (!expr) return <Fragment />;

  // If the children are a function, return the result of that function
  return children;
}
