import * as Sentry from "@sentry/react";

const env = process.env.REACT_APP_ENV;

console.log(`[SENTRY] - initializing on ${env}`);

const production = env === "production";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: env,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost:8080",
        "https://api.yetzpromo.com.br",
      ],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        "https://api.yetzpromo.com.br/api/find",
        "https://api.yetzpromo.com.br/api/banners",
        "https://api.yetzpromo.com.br/api/client/verifyLogin",
        "https://api.yetzpromo.com.br/api/client/sendSms",
        "https://api.yetzpromo.com.br/api/client/login",
        "https://api.yetzpromo.com.br/api/client/action/store",
      ],
    }),
  ],
  tracesSampleRate: production ? 0.1 : 1.0,
  replaysSessionSampleRate: production ? 0.3 : 0.0,
  replaysOnErrorSampleRate: production ? 1.0 : 0.0,
});
