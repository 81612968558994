import { createGlobalStyle } from "styled-components";
import imgClick from "../img/click.svg";

export default createGlobalStyle`

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "Poppins-Regular";
    
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .container {
    width: 1211px;
    margin-left: auto;
    margin-right: auto;

    .swiper-pagination-bullet {
      background: #FFFFFF;
      opacity: 1;
      border-radius: 10px;
      &.swiper-pagination-bullet-active {
        background: #F17E21;
      }
    }
  }

  input {
    touch-action: none !important;
  }

  .container-input{
    width: 100%;
    input{
      border: 1px solid #9CABB8;
      border-radius: 100px;
      height: 40px;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;

      color: rgba(0, 0, 0, 0.5);
      padding-left: 20px;
      width: 100%;
    }
  }

  button {
    &.saiba-mais {
      border: none;
      background: transparent;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      position: relative;
      width: auto !important;
      padding: 0px;
      height: 30px;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        -webkit-mask: url(${imgClick}) no-repeat;
        width: 22px;
        height: 23.5px;
        z-index: 20;
      }
      img {
        width: auto;
      }
    }
  }

  .inputFile{
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    .custom-file {
      width: 100%;
      height: 43px;
      border: 1px dashed #C7C7C7;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: gray;
      padding-left: 20px;
      input[type='file'] {
        display: none;
      }
      &.nome-arquivo {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #565565;
      }
    }
  }

  .abreMenu{
    cursor: pointer;
  }

`;
