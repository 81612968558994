import { useEffect, useState } from "react";
import { Link, Navigate, useHref, useNavigate } from "react-router-dom";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Footer from "../../../components/footer";
import Button from "../../../components/button";
import ModalAguardeEmissao from "../../../components/modal/aguardeEmissao";
import { ModalSaibaMaisNovo } from "../../../components/modal/saibaMaisNovo";
import { When } from "../../../components/when";
import { RenderLogo } from "../../../components/logos";

import { Container } from "./styles";
import { ClickAjuda } from "../../principal/styles";

import img1 from "../../../assets/img/timer-branco.svg";
import img3 from "../../../assets/img/mensagem.svg";
import img4 from "../../../assets/img/calendario.svg";
import img5 from "../../../assets/img/sair.svg";
import imgVoltar from "../../../assets/img/voltar.svg";

import { useAcaoContext } from "../../../contexts/acaoContext";
import { ModalSaibaMais } from "../../../components/modal/saibaMais";

export function PageGrupo() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const dadosLogin = cookies.get("yp_dadosLogin");
  const { productsGroup, configuracao, configLoja } = useAcaoContext();

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl =
    origin_benefit_click_id && origin_benefit_click_id !== ""
      ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
      : baseUrl;

  const [openModalEmissao, setOpenModalEmissao] = useState(false);
  const [modalSaibaMais, setModalSaibaMais] = useState(false);
  const path = useHref().split("/")[1];
  const [produtos, setProdutos] = useState([]);
  const [produtoResgate, setProdutoResgate] = useState();

  const [modalSaibaMaisNovo, setModalSaibaMaisNovo] = useState(false);
  const [produtoSaibaMaisNovo, setProdutoSaibaMaisNovo] = useState();

  useEffect(() => {
    if (productsGroup.length === 0) {
      navigate(`/${path}/loja`);
      return;
    }

    const swiperContainer = document.querySelector(".grupo-container .swiper");
    if (swiperContainer) {
      const swip = swiperContainer.swiper;
      const { innerWidth: width } = window;

      if (width > 480) {
        swip.slideNext();
      }
    }

    setProdutos(productsGroup ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuracao?.key, productsGroup]);

  function openResgate(prod) {
    setProdutoResgate(prod);
    setOpenModalEmissao(true);
  }

  function openSaibaMaisNovo(prod) {
    setProdutoSaibaMaisNovo(prod);
    setModalSaibaMaisNovo(true);
  }

  function getClassName() {
    let className = "container";

    if (productsGroup.length === 1) {
      className += " unicoProduto";
    }
    if (dadosLogin?.max_rewards > 1) {
      className += " multiresgate";
    }

    return className;
  }

  if (!user) {
    return <Navigate to={`/${configuracao?.key}`} replace />;
  }

  return (
    <>
      <Container backgroundColor={configuracao?.background}>
        <div className={getClassName()}>
          <div className="topo">
            <div className="logos">
              <RenderLogo {...configuracao} />
            </div>
            <Link to={linkUrl}>
              <img src={img5} alt="" />
              <span>Sair</span>
            </Link>
          </div>

          <div className="container-sub-header">
            <div className="sub-header">
              <button
                className="voltar-desktop"
                onClick={() => {
                  navigate(`/${path}/loja`);
                }}
              >
                <img src={imgVoltar} alt="" />
                Voltar
              </button>

              <h2>
                Escolha a rede de cinemas <br /> de sua preferência
              </h2>
            </div>
          </div>

          <div className="topo-titulo">
            <div className="texto-personalizado">
              {/* <h2>{configuracao?.choose_reward_subcarrossel_title}</h2> */}
              {/* <p>{configLoja?.general.choose_reward_subtitle}</p> */}
            </div>

            <div className="atencao">
              <img src={img4} alt="" />
              <p>
                Você resgatou: {dadosLogin?.rewards}/{dadosLogin?.max_rewards}
              </p>
            </div>
          </div>
        </div>
        <div className="overlay-modal"></div>
        <div className={`${getClassName()} grupo-container slide-container`}>
          <Swiper
            modules={[Navigation, Pagination]}
            centeredSlides={true}
            slidesPerView="auto"
            grabCursor={true}
            pagination={{ clickable: true }}
            navigation
          >
            {produtos.map((data, index) => {
              return (
                <SwiperSlide className="premio" key={index}>
                  <img src={data?.info?.img} alt="" width={301} height={306} />
                  <h4>{data?.info?.name}</h4>

                  {configuracao?.book_stock === 0 && data.info.blocked === 0 ? (
                    <>
                      <Button
                        title={
                          configuracao?.choose_reward_button_text ??
                          "Escolher prêmio"
                        }
                        onClick={() => openResgate(data.info)}
                      />

                      {configuracao?.disable_know_more !== 1 &&
                      configLoja?.general?.use_personalized_know_more === 1 ? (
                        <button
                          className="saiba-mais"
                          onClick={() => {
                            openSaibaMaisNovo(data);
                          }}
                        >
                          Saiba Mais
                        </button>
                      ) : (
                        <When
                          expr={
                            data.is_group === false &&
                            configuracao?.disable_stock_number !== 1
                          }
                        >
                          <button
                            className="saiba-mais"
                            onClick={() => {
                              setModalSaibaMais(true);
                            }}
                          >
                            Saiba Mais
                          </button>
                        </When>
                      )}
                    </>
                  ) : data.stock_amount === 0 ? (
                    <div className="voucher-indisponivel">
                      <Button title="Voucher Indisponível" />
                      <span>
                        <img src={img1} alt="" />
                        Reposição do estoque em até 48h
                      </span>
                    </div>
                  ) : (
                    <>
                      {data.info.blocked === 0 ? (
                        <Button
                          title={
                            configuracao?.choose_reward_button_text ??
                            "Escolher prêmio"
                          }
                          onClick={() => {
                            openResgate(data?.info);
                          }}
                        />
                      ) : (
                        <div className="voucher-indisponivel">
                          <Button title="Voucher Indisponível" />
                          <span>
                            <img src={img1} alt="" />
                            Reposição do estoque em até 48h
                          </span>
                        </div>
                      )}

                      {configuracao?.disable_know_more !== 1 ? (
                        // configLoja.general.use_personalized_know_more === 1
                        <button
                          className="saiba-mais"
                          onClick={() => {
                            openSaibaMaisNovo(data);
                          }}
                        >
                          Saiba Mais
                        </button>
                      ) : (
                        configuracao?.disable_know_more !== 1 && (
                          <button
                            className="saiba-mais"
                            onClick={() => {
                              setModalSaibaMais(true);
                            }}
                          >
                            Saiba Mais
                          </button>
                        )
                      )}

                      <When expr={!configuracao?.disable_stock_number}>
                        <span>Estoque: {data.stock_amount}</span>
                      </When>
                    </>
                  )}
                </SwiperSlide>
              );
            })}

            <When expr={configuracao?.choose_reward_adive_text}>
              <p>{configuracao?.choose_reward_adive_text}</p>
            </When>
          </Swiper>

          <button
            className="voltar-mobile"
            onClick={() => {
              navigate(`/${path}/loja`);
            }}
          >
            <img src={imgVoltar} alt="" />
            Voltar
          </button>

          <ClickAjuda
            onClick={() => {
              navigate("/" + configuracao?.key + "/ajuda");
            }}
          >
            <img src={img3} alt="" />
            <span>
              Dúvidas? <span className="clique">Clique aqui</span> e fale com
              nosso parceiro YETZ
            </span>
          </ClickAjuda>
        </div>

        <Footer />

        <ModalAguardeEmissao
          isOpen={openModalEmissao}
          handleClose={() => setOpenModalEmissao(false)}
          cor={configuracao?.background}
          prod={produtoResgate}
          url={configuracao?.key}
          user={user?.user_id}
          texto={configuracao?.confirm_choose_modal_text}
        />
      </Container>
      <ModalSaibaMais
        isOpen={modalSaibaMais}
        handleClose={() => {
          setModalSaibaMais(false);
        }}
        openResgate={openResgate}
      />
      <ModalSaibaMaisNovo
        isOpen={modalSaibaMaisNovo}
        cor={configuracao?.background}
        handleClose={() => setModalSaibaMaisNovo(false)}
        prod={produtoSaibaMaisNovo}
        openResgate={openResgate}
      />
    </>
  );
}
