import React, { memo } from "react";
import "pretty-checkbox/dist/pretty-checkbox.min.css";

import { Container } from "./styles";

const CheckBox = ({ title, id, checked, onChange }) => {
  return (
    <Container>
      <div className="pretty p-default p-round">
        <input type="checkbox" id={id} checked={checked} onChange={onChange} />
        <div className="state p-primary-o">
          <label>{title}</label>
        </div>
      </div>
    </Container>
  );
};

export default memo(CheckBox);
