import styled from "styled-components";

export const Container = styled.div`
  .container-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 33px;
    h1 {
      color: #fff;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
      margin-left: 18px;
    }
  }

  .container-nfe {
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 0px 15px;
    .content-nfe {
      width: 90%;
      height: 252px;
    }

    h3 {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 32px;
      margin-bottom: 75px;
    }
  }
`;
