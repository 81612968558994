import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../services/api";
import { getSocket } from "../../config/socket";

import Button from "../button";
import ModalAviso from "../modal/aviso";

import { Container, Back } from "./styles";

import img from "../../assets/img/loader-yetz-3.svg";
import img1 from "../../assets/img/fecha-preto.svg";
import { useAcaoContext } from "../../../src/contexts/acaoContext";

export default function ModalAguardeEmissao(props) {
  const {
    isOpen,
    handleClose,
    user,
    cor,
    prod,
    url,
    automatico,
    textoAguarde,
  } = props;

  const navigate = useNavigate();

  const { isStone } = useAcaoContext();

  const interval = useRef(null);

  const [progress, setProgress] = useState(0);

  const [socket, setSocket] = useState(undefined);

  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();
  const [loader, setLoader] = useState(false);

  async function confirmaResgate() {
    setLoader(true);
    updateProgressBar();
    try {
      const data = await api.post(`/client/action/reward/obtain`, {
        user_id: user,
        variation_id: prod?.variation_id,
      });
      await monitorarResgate(data.id_to_verify);
      setLoader(false);
    } catch (error) {
      updateProgressBar(true);
      handleClose(!active);
      if (error && error[0]) {
        setMensagem(error[0]);
      } else {
        setMensagem("Algo deu errado! Espere um pouco e recarregue a página.");
      }
      setLoader(false);
      setActive(true);
    }
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function monitorarResgate(v) {
    try {
      const response = await api.post(`/client/action/reward/check`, {
        reward_id: v,
      });

      if (!response.done && !response.error) {
        await timeout(5000);
        await monitorarResgate(v);
        return;
      } else if (response.error) {
        if (response.message) {
          setMensagem(response.message);
        } else {
          setMensagem(
            "Algo deu errado! Espere um pouco e recarregue a página."
          );
        }
        updateProgressBar(true);
        setLoader(false);
        handleClose();
        setActive(true);
        return;
      }
      updateProgressBar(true);
      setLoader(false);
      navigate("/" + url + "/finalizacao");
    } catch (error) {
      updateProgressBar(true);
      console.log(error);
    }
  }

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop" && !loader) {
      handleClose();
    }
  }

  function updateProgressBar(finished = false) {
    if (!interval.current) {
      interval.current = setInterval(updateProgressBar, 300);
    }

    console.log(progress);
    if (finished) {
      setProgress(100);
      clearInterval(interval.current);
      interval.current = null;
    } else {
      setProgress((currentVal) => {
        console.log(currentVal);
        return Math.min(currentVal + Math.floor(Math.random() * 4), 98);
      });
    }
  }

  function confirmaResgateSocket() {
    console.log("ddd", user, prod?.variation_id);
    if (isStone) {
      updateProgressBar();
    }
    setLoader(true);

    socket?.emit("purchase", {
      userId: user,
      variationId: prod?.variation_id,
    });
  }

  function onConnect() {
    console.log("connected");

    socket?.emit("joinPrivateChannel", { userId: user });
  }

  function onJoinPrivateResult(data) {
    console.log("join private channel response", JSON.stringify(data));
  }

  function onPurchaseResult(data) {
    console.log("purchase result response", JSON.stringify(data));
    if (!data.success) {
      if (isStone) {
        updateProgressBar(true);
      }
      setLoader(false);

      setMensagem(data.message);
      handleClose();
      setActive(true);
    }
  }

  function onPurchaseConfirmation(data) {
    console.log("purchase confirmation response", JSON.stringify(data));
    if (isStone) {
      updateProgressBar(true);
    }
    setLoader(false);

    if (data.success) {
      navigate("/" + url + "/finalizacao");
    } else {
      setMensagem(data.message);

      handleClose();
      setActive(true);
    }
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("joinPrivateResult", onJoinPrivateResult);
    socket.on("purchaseResult", onPurchaseResult);
    socket.on("purchaseConfirmation", onPurchaseConfirmation);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("joinPrivateResult", onJoinPrivateResult);
      socket.off("purchaseResult", onPurchaseResult);
      socket.off("purchaseConfirmation", onPurchaseConfirmation);
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (isOpen) {
      setSocket(getSocket());
    } else {
      setSocket(undefined);
    }

    if (isOpen && automatico) {
      setLoader(true);
      confirmaResgate();
    }
    // eslint-disable-next-line
  }, [isOpen, automatico]);

  const texto_extra = prod?.extra_reward_modal_info;
  const format_texto_extra = texto_extra?.split("*");

  return (
    <>
      <Container
        id="modal-backdrop"
        data-show={isOpen}
        cor={cor}
        onClick={handleContainerClick}
      >
        <div className={`content resgate ${isStone ? "resgate-stone" : ""}`}>
          {!loader && (
            <button className="close-modal" onClick={() => handleClose(false)}>
              <img src={img1} alt="" width={20} height={20} />
            </button>
          )}
          {prod && <img src={prod.img_modal} alt="" />}
          {!loader && (
            <h3>
              {prod?.confirm_choose_modal_text ??
                "Aguarde, estamos processando seu pedido!"}
            </h3>
          )}
          {!loader && (
            <span className="textoExtra">
              <strong>{format_texto_extra && format_texto_extra[1]}</strong>
              {format_texto_extra && format_texto_extra[2]}
            </span>
          )}
          {!loader && prod?.confirm_choose_modal_body && (
            <p className="textBody">{prod?.confirm_choose_modal_body ?? ""}</p>
          )}

          {!loader ? (
            <>
              {isStone ? (
                <p>Esta confirmação não poderá ser desfeita.</p>
              ) : (
                <p>Após a confirmação, essa ação não poderá ser alterada.</p>
              )}
              <Button
                isStone={isStone}
                title="Confirmar"
                tipo="branco"
                // onClick={() => confirmaResgate()}
                onClick={confirmaResgateSocket}
              />

              <Back
                isStone={isStone}
                onClick={() => {
                  handleClose(false);
                }}
              >
                Voltar
              </Back>
            </>
          ) : (
            <>
              <h3>
                {textoAguarde
                  ? textoAguarde
                  : "Aguarde, estamos processando seu pedido!"}
              </h3>
              <p>{prod?.name}</p>
              {isStone ? (
                <progress value={progress} max="100">
                  {progress}
                </progress>
              ) : (
                <img className="loader" src={img} alt="" />
              )}
            </>
          )}
        </div>
      </Container>

      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />
    </>
  );
}
