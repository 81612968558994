import { Container } from "./styles";
import ImgNfe from "../../../assets/img/nfe.png";
export function ModalInformation(props) {
  const { handleClose } = props;

  return (
    <Container>
      <div className="container">
        <h2>Verifique o seu cupom fiscal</h2>

        <p>
          O Qr Code, o Código de barras ou a Chave da Nota estarão presentes no
          cupom fiscal emitido pelo estabelecimento comercial, após você efetuar
          a compra:
        </p>

        <div className="containerimg">
          <img src={ImgNfe} alt="" />
        </div>

        <button onClick={handleClose}>OK, entendi.</button>
      </div>
    </Container>
  );
}
