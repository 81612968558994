import Button from "../button";

import { CodigoSms } from "./styles";

import img1 from "../../assets/img/fecha-preto.svg";

export function ModalSaibaMaisVoucher(props) {
  const { isOpen, handleClose, prod, cor } = props;

  return (
    <CodigoSms
      id="modal-backdrop"
      data-show={isOpen}
      cor={cor}
      className="backdrop-saibaMais"
    >
      <div className="content saibaMaisNovo">
        <button className="close-modal" onClick={() => handleClose(false)}>
          <img src={img1} alt="" width={20} height={20} />
        </button>
        <div className="conteudo-saiba">
          <>
            <img src={prod?.image} alt="" />
            <h3>{prod?.name}</h3>

            <p
              dangerouslySetInnerHTML={{
                __html: prod?.know_more_text,
              }}
            ></p>
          </>

          <Button title="OK" tipo="branco" onClick={() => handleClose(false)} />
        </div>
      </div>
    </CodigoSms>
  );
}
