import Button from "../button";

import { Container } from "./styles";

import img1 from "../../assets/img/fecha-preto.svg";

export default function ModalPanetonePatiPiva({
  isOpen,
  clickContinue,
  handleClose,
  cor,
}) {
  return (
    <Container data-show={isOpen} cor={cor}>
      <div className="content modalGrandCru">
        <button className="close-modal" onClick={() => handleClose(false)}>
          <img src={img1} alt="" width={20} height={20} />
        </button>
        <div className="titulo">
          <h2>Importante você saber</h2>
        </div>
        <div className="mensagem">
          <span>
            O resgate do Panettone Pati Piva Gotas de Chocolate Personnalité
            será realizado através de um link exclusivo para o Itaú Shop e
            mediante aplicação do cupom. Continue para o resgate para ter acesso
            ao seu benefício.
          </span>
        </div>
        <Button
          title="Continuar para resgate"
          tipo="branco"
          onClick={clickContinue}
        />
        <p
          className="depois"
          onClick={() => {
            handleClose(false);
          }}
        >
          Voltar
        </p>
      </div>
    </Container>
  );
}
