import Button from "../button";

import img from "../../assets/img/icon-error.svg";

import { Container } from "./styles";

export default function ModalErro({ isOpen, handleClose, mensagem, cor }) {
  return (
    <Container data-show={isOpen} cor={cor} className="avisoSucesso">
      <div className="content">
        <div className="img-titulo">
          <img src={img} alt="" />
        </div>
        <div className="body">
          <div className="titulo">{!!mensagem && <h2>{mensagem}</h2>}</div>
          <Button
            tipo="roxo"
            title="OK, ENTENDI"
            onClick={() => handleClose(!isOpen)}
          />
        </div>
      </div>
    </Container>
  );
}
