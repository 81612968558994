import { useNavigate } from "react-router-dom";

import ImgRectangle from "../../assets/img/rectangle-background.png";
import img3 from "../../assets/img/mensagem.svg";
import { ClickAjuda } from "../../pages/principal/styles";
import Footer from "../footer";
import { Tabs } from "./Tabs";

import Modal from "../NewModal/bottom";
import { List } from "./List";
import { Validado } from "./Validado";
import { UseTabProvider } from "./hooks";
import { Header } from "./Header";
import { Container } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { Loader } from "../Loader";
import { ModalInformation } from "./ModalInformation";
import ImgUpdate from "../../assets/img/update.svg";
import { useAcaoContext } from "../../contexts/acaoContext";

export function DashboardQRCode() {
  const navigate = useNavigate();
  const { configuracao } = useAcaoContext();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const arrBlock = [0, 1, 5, 6];

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`client/action/invoice/my`);
      setData(response.invoices);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao carregar dados");
    }
  }

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function updateItens() {
    if (btnDisabled === true) return;
    setBtnDisabled(true);
    try {
      await fetchData();
      toast.success("Dados atualizados com sucesso");
      await timeout(15000);
      setBtnDisabled(false);
    } catch (error) {
      toast.error("Erro ao atualizar dados");
      setBtnDisabled(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UseTabProvider>
      <Container>
        <div className="container conteudo">
          <Header />
          <Tabs
            disabled={Boolean(
              data.find((item) => arrBlock.includes(item.status_id))
            )}
            onUpdate={fetchData}
          />
          <div className="container-info">
            <p>
              Saiba onde encontrar estas informações:{" "}
              <button onClick={() => setModal(!modal)}>CLIQUE AQUI</button>
            </p>

            <button
              className="update"
              onClick={updateItens}
              disabled={btnDisabled}
            >
              <img src={ImgUpdate} alt="" />
              Atualizar
            </button>
          </div>
          {loading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : (
            <List data={data} />
          )}
        </div>

        <div className="container duvida">
          <ClickAjuda
            onClick={() => {
              navigate("/" + configuracao?.key + "/ajuda");
            }}
          >
            <img src={img3} alt="" />
            <span>
              Dúvidas? <span className="clique">Clique aqui</span> e fale com
              nosso parceiro YETZ
            </span>
          </ClickAjuda>
        </div>

        <div className="container-footer">
          <Footer />
        </div>

        <div className="container-rectagle">
          <img src={ImgRectangle} alt="" />
        </div>
      </Container>

      <Modal open={modal} handleClose={() => setModal(!modal)}>
        <ModalInformation handleClose={() => setModal(!modal)} />
      </Modal>

      <Modal open={false} handleClose={() => console.log("opa")}>
        <Validado />
      </Modal>
    </UseTabProvider>
  );
}
