import styled from "styled-components";

export const Container = styled.div`
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: initial;
  .react-select__control {
    background: none;
    border: none;
  }
  .react-select__placeholder {
    color: #fff;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__input-container {
    color: #fff;
  }
  .react-select__single-value {
    color: #fff;
  }
`;
