import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 1px);
  width: 100%;

  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  .overlay-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${({ backgroundColor }) =>
      backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      background: ${({ backgroundColor }) =>
        `linear-gradient(270deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 40%;
      height: 100%;
      z-index: 9;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      background: ${({ backgroundColor }) =>
        `linear-gradient(90deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 40%;
      height: 100%;
      right: 0px;
      z-index: 9;
    }
  }

  .container-sub-header {
    position: relative;
    z-index: 20;
    .sub-header {
      width: 900px;
      margin: 0 auto;
      position: relative;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        margin-top: 0px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      button {
        font-family: "Montserrat-Regular";
        background: transparent;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #ffffff;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          display: block;
          margin-right: 10px;
        }
      }
    }
  }

  .voltar-mobile {
    display: none !important;
  }
  .voltar-desktop,
  .voltar-mobile {
    font-family: "Montserrat-Regular";
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      margin-right: 10px;
    }
  }

  .container {
    width: 1203px;
    position: relative;
    margin-bottom: auto;

    /* min-height: calc(100vh - 120px); */
    &.slide-container {
      width: 90%;
      .swiper {
        width: 100%;

        .swiper-button-prev {
          left: 20%;
        }
        .swiper-button-next {
          right: 20%;
        }
      }
    }
    &.unicoProduto {
      .swiper {
        .swiper-wrapper {
          justify-content: center;
        }
      }
    }
    &.multiresgate {
      .topo-titulo {
        .atencao {
          display: flex;
        }
      }
    }
    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      a {
        display: flex;
        grid-gap: 10px;
        span {
          font-family: "Montserrat-Regular";
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .logos {
      grid-gap: 40px;
      display: flex;
      align-items: baseline;
      margin-bottom: 40px;
      padding-top: 40px;
      img {
        object-fit: contain;
        width: 200px;
        height: 100px;
      }
      .logo {
        width: 230px;
        margin-top: 50px;
      }
    }
    .topo-titulo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      z-index: 20;
      /* .texto-personalizado {
        max-width: 510px;
      } */
      .txt {
        span {
          display: block;
          margin-bottom: 10px;
        }
      }
      .atencao {
        display: none;
        grid-gap: 10px;
        justify-content: center;
        background: linear-gradient(#8b288c, #8b288c) padding-box,
          linear-gradient(270.06deg, #efefef 0.05%, rgba(239, 239, 239, 0) 157%)
            border-box;
        border-radius: 8px;
        border: 4px solid transparent;
        margin-bottom: 20px;
        padding: 0px 20px;
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: #ffffff;
          margin: 8px 0;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 118%;
      text-align: center;
      color: #fff;
      position: relative;
      margin-bottom: 0;
      // &.disabled {
      //     display: none;
      // }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 10px;
    }
    span {
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
    }
    .swiper {
      width: 1200px;
      margin-bottom: 40px;
      position: relative;
      overflow: hidden;
      padding: 50px 0;
      .swiper-wrapper {
        display: flex;
        .swiper-slide {
          opacity: 0.2;
          &.swiper-slide-prev,
          &.swiper-slide-next {
            opacity: 0.8;
          }
          &.swiper-slide-active {
            opacity: 1;
          }
        }
        .premio {
          height: auto;
          width: 277px !important;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.5s;
          position: relative;
          padding-top: 200px;
          padding-bottom: 20px;
          margin-top: 50px;
          z-index: 1;
          button {
            &.saiba-mais {
              margin-top: 20px;
            }
          }
          img {
            padding-left: 15px !important;
            width: 250px;
            height: auto;
            position: absolute;
            top: -60px;
            z-index: 10;
          }
          h4 {
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
            margin-top: 10px;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            margin: 10px 0;
          }
          &:hover {
            transform: scale(0.9);
            transition: all 0.5s;
          }
          button {
            width: 255px;
          }
          .voucher-indisponivel {
            pointer-events: none;
            cursor: none;
            img {
              padding: 0 !important;
              width: 18px !important;
              height: 18px !important;
              position: initial;
            }
            button {
              background: #c4c4c4;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: #5e5f5f;
            }
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #ffffff;
              justify-content: center;
              grid-gap: 5px;
            }
          }
        }
        &.disabled {
          transform: translate3d(0px, 0, 0) !important;
          justify-content: center;
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        color: white;
      }
    }
    .aviso-indisponivel {
      min-height: 523px;
      h5 {
        font-weight: bold;
        font-size: 30px;
        line-height: 118%;
        text-align: center;
        color: #fff;
        position: relative;
        margin-bottom: 10px;
      }
    }

    .personalizado {
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      display: block;

      font-size: 14px;
      font-style: italic;
      line-height: inherit;
      width: 400px;
      margin: 0 auto;
      margin-top: -50px;
    }
  }

  .sac {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 20px 80px -40px rgba(220, 46, 97, 0.6);
    border-radius: 600px;
    cursor: pointer;
    height: 47px;
    padding: 0 20px 0 2px;
    span {
      font-weight: bold;
      font-size: 18px !important;
      line-height: 140%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
    }
    svg {
      fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      rect {
        fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      }
    }
  }

  @media (max-width: 1211px) {
    .overlay-modal {
      height: 100%;
      &::before,
      &::after {
        content: none;
      }
    }
    .slide-container {
      width: 100% !important;
      .swiper-pagination {
        position: initial;
      }
      .swiper-button-prev {
        left: 20% !important;
        top: 94%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-next {
        right: 20% !important;
        top: 94%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .container {
      width: 90%;
      /* min-height: calc(100vh - 120px); */
      .container-sub-header {
        .sub-header {
          width: 100%;
          h2 {
            position: static;
            transform: unset;
            margin-top: 20px;
            width: 100%;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 10px;
          }

          button {
            margin: 16px 0px;
          }
        }
      }
      .voltar-mobile {
        display: flex !important;
        margin: 0 auto;
      }
      .voltar-desktop {
        display: none !important;
      }
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
      }

      .swiper {
        width: auto;
        margin-bottom: 0;
        padding: 0;
        padding-bottom: 20px;

        .swiper-wrapper {
          .premio {
            &:hover {
              transform: scale(1) !important;
            }
            img {
              width: 250px !important;
              top: -40px;
            }
            h4 {
              font-weight: 900;
              font-size: 14px;
              line-height: 150%;
              margin-bottom: 10px;
              width: 90%;
              height: 55px;
            }
            button {
              margin: 0 auto;
              margin-bottom: 10px;
            }
          }
        }
      }

      .topo-titulo {
        flex-direction: column;
        h2 {
          width: 100%;
          font-size: 18px;
          line-height: 23px;
          margin-top: ${({ tipo }) => (tipo === "2" ? "-18px" : "18px")};
          margin-bottom: ${({ tipo }) => (tipo === "2" ? "-30px" : "10px")};
          /* font-size: 26px; */
        }
        p {
          line-height: 112%;
        }
        span {
          width: 100%;
          font-size: 14px;
        }
      }
      .aviso-indisponivel {
        min-height: 200px;
      }
      .personalizado {
        width: 100%;
        margin-top: 0;
      }
    }
    .sac {
      height: auto;
      span {
        font-size: 11px !important;
      }
      svg {
        height: 26px;
        width: 26px;
      }
    }
  }
`;
