import ImgRelogio from "../../../assets/img/check-qr-code.svg";
import { useModal } from "../../../hooks/modaisBarCode";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
export function Parabens(props) {
  const { handleClose } = useModal();
  const navigate = useNavigate();
  return (
    <Container>
      <div className="container-img">
        <img src={ImgRelogio} alt="" />
      </div>

      <h3>Parabéns!</h3>

      <p>
        Seu código foi validado com sucesso! Clique no botão abaixo para efetuar
        o resgate do seu voucher.
      </p>

      <button
        onClick={() => {
          handleClose();
          navigate(-1);
        }}
      >
        Resgatar
      </button>
    </Container>
  );
}
