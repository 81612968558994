import ImgEmptyList from "../../../assets/img/emptyList.svg";
import { Card } from "./Card";
import { Container, EmptyListStyle } from "./styles";

function EmptyList() {
  return (
    <EmptyListStyle>
      <div className="container-img">
        <img src={ImgEmptyList} alt="" />
      </div>

      <p>
        Nenhuma nota adicionada <br /> no momento.
      </p>
      <span>Inclua sua nota e receba seu voucher.</span>
    </EmptyListStyle>
  );
}

export function List(props) {
  const { data } = props;

  return (
    <Container>
      <div className="container-cards">
        {data.length > 0 ? (
          data.map((item) => <Card key={item.id} {...item} />)
        ) : (
          <EmptyList />
        )}
      </div>
    </Container>
  );
}
