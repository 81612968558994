import { TailSpin } from "react-loader-spinner";

export function Loader(props) {
  return (
    <TailSpin
      height="60"
      width="60"
      color="#fff"
      ariaLabel="tail-spin-loading"
      radius="1"
      visible={true}
      {...props}
    />
  );
}
