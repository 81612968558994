import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  .container-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3px;
    width: 500px;
    button {
      border-radius: 10px;
      background: #e86972;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      height: 163px;
      padding: 0px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:disabled {
        opacity: 0.8;
        background-color: #979797;
        transition: all 0.3s ease-in-out;
        cursor: not-allowed;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px;
        margin-top: 12px;
        white-space: pre-line;
      }
    }
  }

  @media (max-width: 1211px) {
    .container-tabs {
      button {
        height: 124px;
      }
    }
  }
`;
