import React from "react";
import { ToastContainer } from "react-toastify";

import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import { Routes } from "./routes";

import GlobalStyled from "./assets/styles/global";
import { AcaoContextProvider } from "./contexts/acaoContext";
import "react-toastify/dist/ReactToastify.css";
import { useAlert } from "./contexts/alert";
import ModalAviso from "./components/modal/aviso";

import { HandTalk } from "./components/HandTalk";
import { Clarity } from "./components/Clarity";
import GoogleTagManager from "./components/GoogleTagManager";
import { Hotjar } from "./components/Hotjar";

const App = () => {
  const { isOpen, message, redirectTo, closeAlert } = useAlert();

  function handleCloseAlert() {
    if (redirectTo) {
      window.location.replace("/" + redirectTo);
    } else {
      closeAlert();
    }
  }

  return (
    <>
      <GlobalStyled />
      <GoogleTagManager gtmId="GTM-TRCKB6HP" />
      <AcaoContextProvider>
        <BrowserRouter>
          <HandTalk />
          <Clarity />
          <Hotjar />
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
        <ToastContainer />
      </AcaoContextProvider>
      <ModalAviso
        isOpen={isOpen}
        mensagem={message}
        handleClose={handleCloseAlert}
      />
    </>
  );
};

export default App;
