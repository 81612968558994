import { QRCode } from "../../../components/QRCode";
import { UseModalProvider } from "../../../hooks/modaisBarCode";
import { UseBarCodeProvider } from "../../../hooks/checkBarCode";
export function PageQRCode() {
  return (
    <UseModalProvider>
      <UseBarCodeProvider>
        <QRCode />
      </UseBarCodeProvider>
    </UseModalProvider>
  );
}
