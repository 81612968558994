import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

import img from "../../assets/img/video.png";

export function BoxVideo({ url }) {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate("/" + url + "/video")}>
      <img src={img} alt="" />
      <span>Assistir vídeo novamente</span>
    </Container>
  );
}
