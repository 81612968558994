import { useState, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { api } from "../../../services/api";

import Footer from "../../../components/footer";
import Button from "../../../components/button";
import ModalAviso from "../../../components/modal/aviso";
import ModalAguardeEmissao from "../../../components/modal/aguardeEmissao";

import { Container } from "./styles";
import { ClickAjuda } from "../../principal/styles";

import img2 from "../../../assets/img/mensagem.svg";
import img3 from "../../../assets/img/sair.svg";

import { useAcaoContext } from "../../../contexts/acaoContext";
import { RenderLogo } from "../../../components/logos";

export function Video() {
  const cookies = new Cookies();
  const { configuracao } = useAcaoContext();

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl = origin_benefit_click_id && origin_benefit_click_id !== ""
    ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
    : baseUrl;

  // const { configuracao, storeAction } = useAcaoContext();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const dadosLogin = cookies.get("yp_dadosLogin");
  const [resgates, setResgates] = useState();

  const [openModalEmissao, setOpenModalEmissao] = useState(false);
  // const [store, setStore] = useState(false);

  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();

  const navigate = useNavigate();

  function endVimeo() {
    document.getElementById("btn-video").classList.remove("disabled");
    // document.getElementById("aviso").classList.add("d-none");
  }

  function handleRedirect() {
    if ((resgates.length || dadosLogin?.rewards) >= dadosLogin?.max_rewards) {
      navigate("/" + configuracao?.key + "/finalizacao");
    } else {
      // if (dadosLogin?.should_auto_reward) {
      //   storeAction({
      //     id_acao: configuracao?.id,
      //     id_user: user?.user_id,
      //     onSuccess: (data) => {
      //       setStore(data);
      //       setOpenModalEmissao(true);
      //     },
      //     onError: (erro) => {
      //       setMensagem(erro);
      //       setActive(true);
      //     },
      //   });
      // } else {
      navigate("/" + configuracao?.key + "/loja");
      // }
    }
  }

  useEffect(() => {
    api
      .post(`/client/action/reward/my`, {
        user_id: user?.user_id,
      })
      .then((data) => {
        setResgates(data.rewards);
      })
      .catch((error) => {
        if (error && error[0]) {
          setMensagem(error[0]);
        } else {
          setMensagem(
            "Algo deu errado! Espere um pouco e recarregue a página."
          );
        }
        setActive(true);
        console.log(error);
      });
  }, [user?.user_id]);

  if (!user) {
    return <Navigate to={`/${configuracao?.key}`} replace />;
  }

  return (
    <Container backgroundColor={configuracao?.background}>
      <div className="container">
        <div className="topo">
          <div className="logos">
            <RenderLogo {...configuracao} />
          </div>
          <Link to={linkUrl}>
            <img src={img3} alt="" />
            <span>Sair</span>
          </Link>
        </div>

        <div className="box">
          {configuracao?.video_link && (
            <Vimeo
              video={configuracao?.video_link}
              onEnd={() => endVimeo()}
            ></Vimeo>
          )}

          <div className="topo-titulo">
            <h2>{configuracao?.video_title}</h2>
            <p>{configuracao?.video_subtitle}</p>
            <p
              id="aviso"
              className={
                configuracao?.video_required === 1 ? "aviso" : "aviso d-none"
              }
            >
              Assista ao vídeo até o final <strong>para poder avançar.</strong>
            </p>

            <div
              className={configuracao?.video_required === 1 ? "disabled" : ""}
              id="btn-video"
            >
              <Button
                title={
                  configuracao?.video_button_to_rewards ?? "Ir para o resgate"
                }
                onClick={() => handleRedirect()}
              />
            </div>
          </div>
        </div>

        <ClickAjuda
          onClick={() => {
            navigate("/" + configuracao?.key + "/ajuda");
          }}
        >
          <img src={img2} alt="" />
          <span className="titulo">
                Dúvidas? <span className="clique">Clique aqui</span>
                {" "}
                {(configuracao?.key === "uniclass" || configuracao?.key === "personnalite") && (
                  <>e fale com nosso parceiro YETZ</>
                )}
          </span>
        </ClickAjuda>
      </div>

      <Footer />

      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />

      <ModalAguardeEmissao
        isOpen={openModalEmissao}
        handleClose={() => setOpenModalEmissao(false)}
        // prod={store?.products?.length && store?.products[0]}
        cor={configuracao?.background}
        url={configuracao?.key}
        user={user?.user_id}
        texto={configuracao?.confirm_choose_modal_text}
      />
    </Container>
  );
}
