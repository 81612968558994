import { QrScanner } from "@yudiel/react-qr-scanner";
import { useModal } from "../../hooks/modaisBarCode";
import { Header } from "../../components/DashboardQRCode/Header";
import ImgQrCode from "../../assets/img/qrCode.svg";
import { Container as ContainerDashboard } from "../../components/DashboardQRCode/styles";
import { Container } from "./styles";
import ImgRectangle from "../../assets/img/rectangle-background.png";
import ModalCenter from "../NewModal/center";
import { Erro } from "../modal/Erro/index";
import { Processando } from "../modal/processando";
import { Parabens } from "../modal/Parabens";
import { useBarCode } from "../../hooks/checkBarCode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//sudo docker system prune -a --volumes

export function QRCode() {
  const { modal, setModal } = useModal();
  const { checkQrCode, state, setState } = useBarCode();
  const [process, setProcess] = useState(true);
  const navigate = useNavigate();

  return (
    <ContainerDashboard>
      <Container className="container">
        <Header />

        <div className="container-text-img">
          <img src={ImgQrCode} alt="" />
          <h1>Ler QR Code</h1>
        </div>

        <div className="container-nfe">
          <div className="content-nfe">
            <div
              style={{
                width: "100%",
                height: "257px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "30px solid rgba(0, 0, 0, 0.5)",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 9999,
                borderRadius: "16px",
              }}
            ></div>
            {!modal ? (
              <QrScanner
                constraints={{ facingMode: "environment" }}
                onResult={async (result, error) => {
                  if (!!result) {
                    setState({});
                    setModal(true);
                    if (!modal) {
                      await checkQrCode(result?.text, () => {
                        setProcess(false);
                      });
                    }
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                videoStyle={{
                  width: "100%",
                  height: "257px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            ) : (
              <></>
            )}
          </div>

          <h3>
            Posicione a câmera do seu celular sobre o <br />
            QR Code para escanear.
            <br />
          </h3>
        </div>
      </Container>
      <div className="container-rectagle">
        <img src={ImgRectangle} alt="" />
      </div>

      <ModalCenter
        open={modal}
        handleClose={() => {
          setModal(!modal);
          navigate(-1);
        }}
      >
        {process ? (
          <Processando message={state.message} />
        ) : state.invoice_id ? (
          <Processando message={state.message} />
        ) : state.done && !state.error ? (
          <Parabens />
        ) : state.done === false ? (
          <Processando message={state.message} />
        ) : (
          <Erro message={state.message} />
        )}
      </ModalCenter>
    </ContainerDashboard>
  );
}
