import styled from "styled-components";

export const Container = styled.div`
  padding-top: 24px;

  .container-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 327px));
    justify-content: center;
    grid-gap: 16px;
  }

  @media (max-width: 1211px) {
    .container-cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`;

export const EmptyListStyle = styled.div`
  max-width: 327px;
  margin: 0 auto;
  height: 286px;
  border-radius: 15px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 46px;

  p {
    color: #e86972;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 1211px) {
    width: 100%;
  }
`;
