import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.color};
  height: calc(100vh - 84px);
  position: relative;
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    .container-text {
      margin-left: 36px;
      h1 {
        font-family: "Lato", sans-serif;
        color: #fff;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        margin: 0px;
        margin-bottom: 16px;
      }

      p {
        font-family: "Lato", sans-serif;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        margin: 0px;
        width: 239px;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 80px;
  }

  @media (max-width: 1211px) {
    padding: 0 10%;
    height: 100vh;
    .content {
      height: auto;
      padding-top: 60px;
      flex-direction: column;
      align-items: flex-start;
      .container-text {
        margin-left: 0px;
      }
      .container-img {
        margin-left: 90px;
        margin-bottom: -16px;
        img {
          width: 182px;
        }
      }
    }

    .logo {
      margin-bottom: 122px;
      img {
        width: 40px;
      }
    }
  }
`;
