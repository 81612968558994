import styled from "styled-components";
import { Container as Button } from "../../button/styles";

export const Container = styled.div`
  padding-top: 84px;
  padding-bottom: 31px;

  h3 {
    margin: 0px;
    padding: 0px;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  .container-modal {
    width: 90%;
    margin: 0 auto;
  }
  .btn-fechar {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .container-title {
    h3 {
      font-size: 24px;
      color: #000d3c;
      text-align: center;
    }
  }

  .container-descricao {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #5e5f5f;
      font-size: 16px;
    }

    .icon {
      height: 18px;
      margin-left: 8px;
      margin-right: 8px;
    }

    p {
      color: #000d3c;
      font-size: 16.8px;
      font-weight: 700;
    }
  }

  .container-banner-novidade {
    height: 237px;
    margin-bottom: 33px;
    img {
      position: absolute;
      width: 100%;
      left: 0;
    }
  }

  .container-detalhes {
    text-align: center;
    margin-bottom: 46px;
    p {
      color: #5e5f5f;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .container-btns {
    ${Button} {
      width: 100%;
      margin-bottom: 8px;
    }

    button {
      &:last-child {
        color: #5e5f5f;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        display: block;
        margin: 0 auto;
      }
    }
  }
`;
