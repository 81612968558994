import styled from "styled-components";

export const Container = styled.button`
  border: none;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 383px;
  height: 40px;
  cursor: pointer;
  background: ${(props) => props.button_color || "#f17e21"} !important;
  border-radius: ${(props) => (props.isStone ? "7px" : "100px")};
  font-weight: ${(props) => (props.isStone ? "600" : "700")};
  font-size: ${(props) => (props.isStone ? "14px" : "16px")};
  line-height: 19px;
  color: ${(props) => (props.isStone ? "#003605" : "#ffffff")};
  transition: all 0.5s;
  font-family: ${(props) => props.isStone && "Inter"};
  font-weight: ${(props) => props.isStone && "600"};
  margin-top: ${(props) => props.isStone && "44px"};
  max-width: ${(props) => props.isStone && "308px"} !important;

  &:disabled {
    background: #c4c4c4;
    color: #5e5f5f;
    cursor: not-allowed;
    transition: all 0.5s;
  }

  @media (max-width: 1211px) {
    width: 100%;
    height: ${(props) => (props.isStone ? "40px" : "34px")};
    font-weight: ${(props) => props.isStone && "600"};
    font-size: ${(props) => (props.isStone ? "14px" : "16px")};
    margin-left: auto;
    margin-right: auto;
  }
`;
