import styled from "styled-components";

export const ContainerRedirect = styled.div`
  background-color: #00ea33;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo_ton {
    margin: 100px 0;
  }
  progress {
    border-radius: 20px;
    height: 5px;
    width: 270px;
    margin-bottom: 50px;
  }
  progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 20px;
  }
  progress::-webkit-progress-value {
    background-color: #003605;
    border-radius: 20px;
  }
  progress::-moz-progress-bar {
    /* style rules */
  }
  .footer {
    width: 173px;
    p {
      font-size: 8px;
      font-weight: 300;
      line-height: 10.4px;
      letter-spacing: 0.07em;
      text-align: center;
    }
  }
`;
