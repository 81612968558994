import img from "../../assets/img/logo-footer.svg";

import { useAcaoContext } from "../../contexts/acaoContext";

import { Container } from "./styles";

export default function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();

  const { isStone } = useAcaoContext();

  return (
    <Container isStone={isStone}>
      <div className="container-footer">
        <span>
          YETZ LTDA | CNPJ: 28.325.166/0001-05 | {currentYear}  Todos os
          direitos reservados
        </span>
        <div className="imgs">
          <img src={img} alt="" />
        </div>
      </div>
    </Container>
  );
}
