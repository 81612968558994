import React, { useState } from "react";
import Button from "../button";
import AlertIcon from "../../assets/icons/alert_preto.svg";
import {
  ContainerStone,
  InnerContainer,
  Content,
  ImageWrapper,
  Title,
  Message,
} from "./styles";

import ModalFaleConosco from "../modal/faleConosco";

const ErrorLogin = ({ user, configuracao }) => {
  const [openFaleConosco, setOpenFaleConosco] = useState(false);

  const handleFaleConoscoClick = () => {
    const whatsappUrl = "https://wa.me/551130049136?text=Ol%C3%A1!%20Gostaria%20de%20ajuda";
  
    try {
      window.location.href = whatsappUrl;
    } catch (e) {
      console.error("Erro ao redirecionar:", e);
    }
  };

  return (
    <ContainerStone>
      <InnerContainer>
        <Content>
          <ImageWrapper>
            <img src={AlertIcon} alt="Alert Icon" />
          </ImageWrapper>
          <Title>Desculpe, estamos passando por instabilidade</Title>

          <Message>
            Estamos fazendo melhorias por aqui. Por favor, tente novamente mais
            tarde, ou entre em contato conosco.
          </Message>
        </Content>
        <Button title="Fale com o Ton" onClick={handleFaleConoscoClick}></Button>
      </InnerContainer>

      <ModalFaleConosco
        isOpen={openFaleConosco}
        handleClose={() => setOpenFaleConosco(false)}
        id_usuario={user?.user_id}
        acao={configuracao?.name}
        link_acao={configuracao?.key}
        login={user?.user_login}
        cor={configuracao?.background}
        label_login={configuracao?.login_label}
        lgpd_cpf_truncado={configuracao?.login_type_cpf_truncated}
        action_id={configuracao?.id}
      />
    </ContainerStone>
  );
};

export default ErrorLogin;
