import React from "react";
import styles from "./styles";

const CircularProgress = () => {
    return (
        <div style={styles.circularProgressWrapper}>
            <svg style={styles.circularProgress} viewBox="0 0 50 50">
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke="rgba(255, 255, 255, 0.3)"
                    strokeWidth="5"
                />
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="5"
                    strokeDasharray="125"
                    strokeDashoffset="100"
                    strokeLinecap="round"
                    transform="rotate(-90 25 25)"
                    style={styles.spin}
                />
            </svg>
        </div>
    );
};

export default CircularProgress;
