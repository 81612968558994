import styled from "styled-components";
import { Container as Button } from "../../button/styles";

export const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 31px;

  h3 {
    margin: 0px;
    padding: 0px;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  .container-modal {
    width: 90%;
    margin: 0 auto;
    align-items: center;
    text-align: center;
  }

  .btn-fechar {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  
  .container-title {
    h3 {
      font-size: 24px;
      color: #000d3c;
      text-align: center;
      padding-top: 10px;
    }
  }

  .container-sub-title p {
    margin-bottom: 50px;
  }

  .container-btns {
    ${Button} {
      width: 100%;
      margin-bottom: 8px;
    }

    button {
      &:last-child {
        color: white;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
`;
