import { useNavigate } from "react-router-dom";
import { Header } from "../DashboardQRCode/Header";
import { Container as ContainerDashboard } from "../DashboardQRCode/styles";
import ModalCenter from "../NewModal/center";
import { useModal } from "../../hooks/modaisBarCode";
import { useBarCode } from "../../hooks/checkBarCode";
import { FormKey } from "./Form";

import { Container } from "./styles";

import ImgKeyCode from "../../assets/img/key.svg";
import ImgRectangle from "../../assets/img/rectangle-background.png";
import { Processando } from "../modal/processando";
import { Parabens } from "../modal/Parabens";
import { Erro } from "../modal/Erro/index";
import { useState } from "react";

export function KeyCode() {
  const { modal, setModal } = useModal();
  const { state } = useBarCode();
  const [process, setProcess] = useState(false);
  const navigate = useNavigate();

  return (
    <ContainerDashboard>
      <Container className="container">
        <Header />

        <div className="container-text-img">
          <img src={ImgKeyCode} alt="" />
          <h1>Digitar Chave de Acesso</h1>
        </div>

        <FormKey setProcess={setProcess} />
      </Container>

      <div className="container-rectagle">
        <img src={ImgRectangle} alt="" />
      </div>

      <ModalCenter
        open={modal}
        handleClose={() => {
          setModal(!modal);
          navigate(-1);
        }}
      >
        {process ? (
          <Processando message={state.message} />
        ) : state.invoice_id ? (
          <Processando message={state.message} />
        ) : state.done && !state.error ? (
          <Parabens />
        ) : state.done === false ? (
          <Processando message={state.message} />
        ) : (
          <Erro message={state.message} />
        )}
      </ModalCenter>
    </ContainerDashboard>
  );
}
