import Cookies from "universal-cookie";

function getSessionData() {
  let data = new Cookies().get("yp_dadosLogin");

  if (!data) {
    data = JSON.parse(localStorage.getItem("yp_dadosLogin"));
  }

  return data;
}

export { getSessionData };
