import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  border-radius: 7px;
  display: flex;
  padding: 10px;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;
  img {
    width: 65px;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #6f3e98;
  }
`;
