import * as Yup from "yup";

export const initialValues = {
  key: "",
};

export const validation = Yup.object().shape({
  key: Yup.string()
    .required("Campo obrigatório")
    .min(44, "caracteres insuficientes")
    .max(44, "Chave inválida"),
});
