import ImgErrro from "../../../assets/img/erro-qr-code.svg";
import { useModal } from "../../../hooks/modaisBarCode";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";

export function Erro(props) {
  const { message } = props;
  const { handleClose } = useModal();
  const navigate = useNavigate();
  return (
    <Container>
      <div className="container-img">
        <img src={ImgErrro} alt="" />
      </div>

      <h3>Ops!</h3>

      <p>{message}</p>

      <button
        onClick={() => {
          handleClose();
          navigate(-1);
        }}
      >
        Voltar
      </button>
    </Container>
  );
}
