import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 1px);
  width: 100%;

  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  // CSS layout STONE
  * {
    color: ${({ isStone }) => isStone && "#000"} !important;
    font-family: ${({ isStone }) => isStone && "Inter"} !important;
  }
  .swiper-button-prev {
    color: ${({ isStone }) => (isStone ? "#017F1CFA" : "#FFFFFF")} !important;
  }
  .swiper-button-next {
    color: ${({ isStone }) => (isStone ? "#017F1CFA" : "#FFFFFF")} !important;
  }
  .swiper-pagination-bullet {
    background: ${({ isStone }) => (isStone ? "#DBDBDB" : "")} !important;
  }
  .swiper-pagination-bullet-active {
    background: ${({ isStone }) => (isStone ? "#017F1CFA" : "")} !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: ${({ isStone }) => (isStone ? "#017F1CFA" : "")} !important;
  }
  .saiba-mais {
    font-weight: ${({ isStone }) => (isStone ? "600" : "900")} !important;
    &::before {
      background-color: ${({ isStone }) =>
    isStone ? "#017F1CFA" : ""} !important;
    }
  }

  .overlay-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${({ backgroundColor }) =>
    backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      background: ${({ backgroundColor }) =>
    `linear-gradient(270deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 50%;
      height: 100%;
      z-index: 9;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      background: ${({ backgroundColor }) =>
    `linear-gradient(90deg, rgba(111, 62, 152, 0.00) 31.59%, ${backgroundColor} 91.06%)`};
      width: 50%;
      height: 100%;
      right: 0px;
      z-index: 9;
    }
  }

  .loader {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    position: relative;
  }

  .container {
    width: 1203px;
    position: relative;
    margin-bottom: auto;

    /* min-height: calc(100vh - 120px); */
    &.slide-container {
      width: 90%;
      .swiper {
        /* width: 100%; */

        /* .swiper-button-prev {
          left: 20%;
        }
        .swiper-button-next {
          right: 20%;
        } */
      }
    }
    &.unicoProduto {
      .swiper {
        .swiper-wrapper {
          justify-content: center;
          width: fit-content;
        }
      }
    }
    &.multiresgate {
      .topo-titulo {
        flex-direction: column;
        .atencao {
          display: flex;
          background: ${({ backgroundColor }) =>
    `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box,
            linear-gradient(
                270.06deg,
                #efefef 0.05%,
                rgba(239, 239, 239, 0) 157%
              )
              border-box` ||
    `linear-gradient(#8b288c, #8b288c) padding-box,
            linear-gradient(
                270.06deg,
                #efefef 0.05%,
                rgba(239, 239, 239, 0) 157%
              )
              border-box`};
          transition: all 0.1s;
          cursor: pointer;
          &:hover {
            background: #fff;
            transition: all 0.1s;
            img {
              filter: invert(1);
            }
            p {
              color: ${({ backgroundColor }) => backgroundColor || "#000"};
            }
          }
        }
      }
    }
    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      a {
        display: flex;
        grid-gap: 10px;
        span {
          font-family: "Montserrat-Regular";
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
        }
      }
      .verResgatesCupom {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 10px;
        img {
          height: 17px;
        }
        p {
          margin: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration-line: underline;
          cursor: pointer;
          color: #ffffff;
          font-family: "Montserrat-Regular";
        }
      }
    }
    .logos {
      grid-gap: 40px;
      display: flex;
      align-items: baseline;
      margin-bottom: 40px;
      padding-top: 40px;
      img {
        object-fit: contain;
        width: 200px;
        height: 100px;
      }
      .logo {
        width: 230px;
        margin-top: 50px;
      }
      .fecha-tela {
        width: 14px;
        height: 14px;
        margin-top: 20px;
        margin-bottom: 40px;
        margin-left: 10px;
      }
    }
    .topo-titulo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      z-index: 20;
      .texto-personalizado {
        font-family: ${({ isStone }) => isStone && "Inter"} !important;
        font-weight: ${({ isStone }) => isStone && "700"} !important;
        font-size: ${({ isStone }) => isStone && "18px"} !important;
        line-height: ${({ isStone }) => isStone && "21.6px"} !important;
        color: ${({ isStone }) => isStone && "#2B323B"} !important;
        margin-bottom: ${({ isStone }) => isStone && "10px"} !important;
      }
      p {
        font-size: 18px;
      }
      .descricao-titulo {
        margin: 10px 0;
      }
      .txt {
        span {
          display: block;
          margin-bottom: 10px;
        }
      }
      .atencao {
        display: none;
        grid-gap: 10px;
        justify-content: center;
        background: linear-gradient(#8b288c, #8b288c) padding-box,
          linear-gradient(270.06deg, #efefef 0.05%, rgba(239, 239, 239, 0) 157%)
            border-box;
        border-radius: 8px;
        border: 4px solid transparent;
        margin-bottom: 20px;
        padding: 0px 20px;
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: #ffffff;
          margin: 8px 0;
        }
      }
      .verResgatesCupom {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        p {
          margin: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 118%;
      text-align: center;
      color: #fff;
      position: relative;
      margin-bottom: 0;
      // &.disabled {
      //     display: none;
      // }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 10px;
    }
    span {
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
    }
    .swiper {
      width: 1000px;
      margin-bottom: 40px;
      position: relative;
      overflow: hidden;
      padding: 50px 0;
      .swiper-wrapper {
        display: flex;
        .swiper-slide {
          opacity: 0.2;
          &.swiper-slide-prev,
          &.swiper-slide-next {
            opacity: 0.8;
          }
          &.swiper-slide-active {
            opacity: 1;
          }
        }

        .premio {
          width: 300px !important;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.5s;
          position: relative;
          padding-top: 200px;
          margin-top: 50px;
          z-index: 1;
          height: ${({ isStone }) => (isStone ? "400px" : "auto")};
          padding-bottom: 40px;
          button {
            &.saiba-mais {
              position: absolute;
              bottom: 0;
            }
            &.stone {
              color: #000 !important;
              border-radius: 7px;
              background-color: #f1f3f5;
              width: 255px !important;
              height: 40px !important;
              margin-bottom: 30px !important;
              &:before {
                display: none;
              }
            }
          }
          img {
            padding-left: 15px !important;
            width: 250px;
            height: auto;
            position: absolute;
            top: -60px;
            z-index: 10;
          }
          .name-product-or-group {
            height: ${({ isStone }) => (isStone ? "20px" : "40px")};
            margin-top: 10px;
            display: flex;
            align-items: start;
            justify-content: center;
            margin-bottom: 8px;
            h4 {
              font-weight: bold;
              font-size: 16px;
              line-height: 120%;
              text-align: center;
              color: #fff;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0px;
              padding: 0px;
              font-family: ${({ isStone }) => isStone && "Inter"} !important;
            }
            span {
              margin: 0;
              display: block;
            }
          }
          .name-description {
            padding: 0;
            height: ${({ isStone }) => (isStone ? "20px" : "40px")};
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            p {
              font-weight: normal;
              font-size: 14px;
              line-height: 120%;
              text-align: center;
              color: #fff;
              margin: 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            span {
              margin: 0;
              display: block;
            }
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            margin: 10px 0 50px;
          }
          &:hover {
            transform: scale(1.1);
            transition: all 0.5s;
          }
          button {
            width: 255px;
          }
          .voucher-indisponivel {
            pointer-events: none;
            cursor: none;            
            img {
              padding: 0 !important;
              width: 18px !important;
              height: 18px !important;
              position: initial;             
            }
            button {
              background: #c4c4c4 !important;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: #5e5f5f;
              margin: 0 auto;
              color: ${({ isStone }) => isStone && "#C7C7C7"} !important;
              background: ${({ isStone }) => isStone && "#F1F3F5"} !important;
              line-height: ${({ isStone }) => isStone && "16.94px"} !important;
              size: ${({ isStone }) => isStone && "14px"} !important;
              font-weight: ${({ isStone }) => isStone && "600"} !important;
              height: ${({ isStone }) => isStone && "40px"} !important;
            }
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #ffffff;
              justify-content: center;
              grid-gap: 5px;
              margin: 10px 0;
              color: ${({ isStone }) => isStone && "#017F1CFA"} !important;
              font-family: ${({ isStone }) => isStone && "Inter"} !important;
              font-size: ${({ isStone }) => isStone && "12px"} !important;
              font-weight: ${({ isStone }) => isStone && "400"} !important;
              line-height: ${({ isStone }) => isStone && "20.4px"} !important;
            }
          }
          .voucher-indisponivel-uniclass {
            margin-top: -12px;
            pointer-events: none;
            cursor: none;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 18px;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
              background: #c4c4c4 !important;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: #5e5f5f;
            }
            .texto {
              width: 273px;
              display: flex;
              align-items: center;
              grid-gap: 10px;
              margin-top: 10px;
              img {
                padding: 0 !important;
                width: 19px !important;
                height: 24px !important;
                position: static;
              }
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #ffffff;
                justify-content: center;
                margin: 0;
                display: inline;
                .nivel {
                  background: #f17e21;
                  border-radius: 17.9263px;
                  padding: 2px 6px;
                  font-weight: 900;
                  font-size: 12px;
                  line-height: 13px;
                  color: #ffffff;
                }
              }
            }
          }
          .visualizarCupom {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 10px;
            margin-top: 20px;
            img {
              width: auto;
              position: static;
            }
            p {
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-decoration-line: underline;
              margin: 0;
              cursor: pointer;
            }
          }
        }
        &.disabled {
          transform: translate3d(0px, 0, 0) !important;
          justify-content: center;
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        color: white;
      }
    }
    .aviso-indisponivel {
      min-height: 523px;
      h5 {
        font-weight: bold;
        font-size: 30px;
        line-height: 118%;
        text-align: center;
        color: #fff;
        position: relative;
        margin-bottom: 10px;
      }
    }

    .personalizado {
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      display: block;

      font-size: 14px;
      font-style: italic;
      line-height: inherit;
      width: 400px;
      margin: 0 auto;
      margin-top: -50px;
    }
  }

  .sac {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 20px 80px -40px rgba(220, 46, 97, 0.6);
    border-radius: 600px;
    cursor: pointer;
    height: 47px;
    padding: 0 20px 0 2px;
    span {
      font-weight: bold;
      font-size: 18px !important;
      line-height: 140%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
    }
    svg {
      fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      rect {
        fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      }
    }
  }

  @media (max-width: 768px) {
      .texto-personalizado {
        max-width: ${({ isStone }) => isStone && "256px"} !important;
      }
  }

  @media (max-width: 1211px) {

    .overlay-modal {
      height: 100%;
      &::before,
      &::after {
        content: none;
      }
    }
    .slide-container {
      width: 100% !important;
      .swiper-pagination {
        position: initial;
      }
      .swiper-button-prev {
        left: 20% !important;
        top: 94%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-next {
        right: 20% !important;
        top: 94%;
        &:after {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .container {
      width: 90%;
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
      }
      .swiper {
        width: auto;
        margin-bottom: 0;
        padding: 0;
        padding-bottom: 20px;
        .swiper-wrapper {
          .premio {
            width: auto !important;
            .voucher-indisponivel-uniclass {
              margin-bottom: 20px;
            }
            &:hover {
              transform: scale(1) !important;
            }
            img {
              width: 250px !important;
              top: -40px;
            }
            .name-product-or-group {
            height: ${({ isStone }) => (isStone ? "20px" : "40px")};
              width: 300px;
              margin-bottom: 8px;
              display: flex;
              align-items: start;
              justify-content: center;
              h4 {
                font-size: 14px;
                width: 90%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0px;
                margin: 0px;
                font-family: ${({ isStone }) => isStone && "Inter"} !important;
                font-weight: ${({ isStone }) => isStone && "700"} !important;
                font-size: ${({ isStone }) => isStone && "20px"} !important;
                line-height: ${({ isStone }) => isStone && "22px"} !important;
                color: ${({ isStone }) => isStone && "#2B323B"} !important;
                margin-bottom: ${({ isStone }) => isStone && "0px"} !important;
              }
            }
            .name-description {
              height: ${({ isStone }) => (isStone ? "20px" : "40px")};
              margin-bottom: 8px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: ${({ isStone }) => isStone && "25px"} !important;
              font-family: ${({ isStone }) => isStone && "Inter"} !important;
              font-weight: ${({ isStone }) => isStone && "400"} !important;
              font-size: ${({ isStone }) => isStone && "12px"} !important;
              line-height: ${({ isStone }) => isStone && "20.4px"} !important;
              color: ${({ isStone }) => isStone && "#000E18"} !important;
              p {
                font-size: 12px;
                width: 90%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            button {
              margin: 0px auto 10px;
              font-size: ${({ isStone }) => isStone && "14px"} !important;
              height: ${({ isStone }) => isStone && "40px"} !important;
            }
            .visualizarCupom {
              img {
                width: auto !important;
              }
            }
          }
        }
      }

      .topo-titulo {
        flex-direction: column;
        h2 {
          width: 100%;
          font-size: 18px;
          line-height: 23px;
          margin-top: ${({ tipo }) => (tipo === "2" ? "-18px" : "18px")};
          margin-bottom: ${({ tipo }) => (tipo === "2" ? "-30px" : "10px")};
          /* font-size: 26px; */
        }
        p {
          line-height: 112%;
        }
        span {
          width: 100%;
          font-size: 14px;
        }
        .atencao {
          p {
            font-size: 15px;
          }
        }
      }
      .aviso-indisponivel {
        min-height: 200px;
      }
      .personalizado {
        width: 100%;
        margin-top: 0;
      }
    }
    .sac {
      height: auto;
      span {
        font-size: 11px !important;
      }
      svg {
        height: 26px;
        width: 26px;
      }
    }
  }

.swiper-slide {
  opacity: 1 !important;
  filter: none !important;
  user-select: none;
}

  @media (min-width: 1212px) {
    .premio{
       height: ${({ isStone }) => (isStone ? "427px" : "")} !important;
  }
}
`;
