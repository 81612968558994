import styled from "styled-components";
// yarn add @mui/icons-material@5.8.4  @material-ui/core@4.12.4
export const Container = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 19px 21px 18px 21px;

  .container-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 11px;
    margin-bottom: 14px;
  }

  .container-data {
    margin-bottom: 20px;
  }

  .description {
    h4 {
      font-family: "Lato", sans-serif;
      text-align: left;
      color: #e86972;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
    }

    p {
      font-family: "Lato", sans-serif;
      color: #000;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }
  }

  .container-status {
    display: flex;
    align-items: center;
    &.processando {
      h5 {
        color: #e86972;
      }

      small {
        background-color: #e86972;
      }
    }

    &.sucesso {
      h5 {
        color: #57b847;
      }

      small {
        background-color: #57b847;
      }
    }

    &.atencao {
      h5 {
        color: #f17e21;
      }

      small {
        background-color: #f17e21;
      }
    }

    h5 {
      width: 285px;
      background-color: #eaeaea;
      font-family: "Lato", sans-serif;
      height: 27px;
      border-radius: 2px;
      text-align: center;
      line-height: normal;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    small {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 12px;
      height: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 2px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Lato", sans-serif;
    }
  }

  .container-voucher {
    h2 {
      font-family: "Lato", sans-serif;
      color: #e86972;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .container-resultado {
    border-radius: 15px;
    background: #eee;
    height: 108px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 21px;
    margin-top: 30px;
    &.update {
      justify-content: center;
      align-items: center;
    }

    &.erro {
      height: auto;
      display: flex;
      align-items: center;
      p {
        color: #e86972;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px;
        margin-left: 8px;
      }
    }

    .container-img {
      img {
        width: 88px;
        height: 57px;
        object-fit: contain;
        border-radius: 5px;
      }
    }

    .container-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        border: none;
        border-radius: 100px;
        background: #e86972;
        width: fit-content;
        padding: 0 24px;
        height: 40px;
        color: #fff;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
          background: #e86972;
          opacity: 0.8;
          transition: all 0.2s ease-in-out;
        }
      }

      span {
        color: #e86972;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        display: flex;
        align-items: center;

        margin-top: 10px;
        img {
          display: block;
          margin-right: 5px;
        }
      }
      .saibaMaisVoucher {
        cursor: pointer;
      }
    }
  }
`;

export const LoadLink = styled.div`
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 100%;
  .loader {
    width: 150px;
  }
  .erro {
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 30px;
      color: #6f3e98;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #6f3e98;
      margin-top: 10px;
    }
  }
`;
