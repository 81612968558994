import Iframe from "react-iframe";
import { useAcaoContext } from "../../contexts/acaoContext";
import { Container } from "./styles";
export function SaibaMais() {
  const { saibaMaisLinkEstoque } = useAcaoContext();
  return (
    <Container>
      <Iframe
        url={`https://yetz.app/passo-a-passo/promo/${saibaMaisLinkEstoque}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
      />
    </Container>
  );
}
