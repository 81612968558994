import styled from "styled-components";
import img2 from "../../../assets/img/carteira.svg";
import { Container as Select } from "../../../components/select/styles";

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 1px);
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  .voltar {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-top: 3px;
    }
    span {
      display: inline-block;
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 19px;
      color: rgb(255, 255, 255);
    }
  }
  .overlay-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${({ backgroundColor }) =>
      backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};
  }

  .loader {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    position: relative;
    grid-column: span 3;
  }

  .container {
    width: 1203px;
    position: relative;

    /* min-height: calc(100vh - 120px); */

    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 20;
      a {
        display: flex;
        grid-gap: 10px;
        span {
          font-family: "Montserrat-Regular";
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
        }
      }
      .links-lateral {
        display: flex;
        grid-gap: 20px;
        .voltarTopo {
          cursor: pointer;
          display: flex;
          grid-gap: 5px;
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;

            color: #ffffff;
          }
        }
      }
      .links-topo {
        .voltar {
          position: static;
          transform: none;
          margin-bottom: 10px;
        }
      }
    }
    .logos {
      grid-gap: 40px;
      display: flex;
      align-items: baseline;
      margin-bottom: 40px;
      padding-top: 40px;
      img {
        object-fit: contain;
        width: 200px;
        height: 100px;
      }
      .logo {
        width: 230px;
        margin-top: 50px;
      }
    }
    .topo-titulo {
      display: flex;
      justify-content: space-between;
      grid-gap: 10px;
      position: relative;
      z-index: 20;
      margin: 20px 0;
      align-items: center;
      p {
        font-size: 16px;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin: 0;
        img {
          width: 34px;
        }
      }
      .titulo-contador {
        p {
          border-bottom: 1px solid #ffffff;
          margin-bottom: 5px;
          padding-bottom: 5px;
        }
        ${(props) => {
          console.log(props.wallet_counter_color);
          return "";
        }}
        .contador-voucher {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: ${(props) => props.wallet_counter_color || "#fff"};
          display: flex;
          align-items: center;
          grid-gap: 5px;
          position: relative;
          padding-left: 30px;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            background-color: ${(props) =>
              props.wallet_counter_color || "#fff"};
            -webkit-mask: url(${img2}) no-repeat;
            width: 21px;
            height: 21px;
            z-index: 20;
            transition: opacity 0.5s;
          }
          strong {
            font-weight: 900;
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .filtros {
        display: flex;
        grid-gap: 10px;
        height: 100%;
        input {
          border-radius: 100px;
          border: 1px solid #5e5f5f;
          background: #fff;
          width: 343px;
          padding: 0 20px;
        }
        button {
          width: 89px;
          font-size: 14px;
        }
        .abreFiltro {
          cursor: pointer;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 118%;
      text-align: center;
      color: #fff;
      position: relative;
      margin-bottom: 0;
      // &.disabled {
      //     display: none;
      // }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 10px;
    }
    span {
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
    }
    &.carteira-container {
      width: 1200px;
      margin-bottom: 40px;
      position: relative;
      overflow: hidden;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      margin-bottom: auto;
      margin-top: 20px;
      .premio {
        border-radius: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 20px;
        z-index: 1;
        &.indisponivel {
          .link {
            pointer-events: none;
            filter: grayscale(1);
            opacity: 0.8;
            cursor: none;
          }
        }
        .nome-descricao {
          border-bottom: 1px solid #5e5f5f;
          margin-bottom: 10px;
          height: 50px;
          h4 {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: initial;
            color: #535353;
            margin: 0;
            margin-bottom: 10px;
          }
          span {
            margin: 0;
            display: block;
          }
        }
        .link {
          border-radius: 30px;
          border: 1px solid #57b847;
          display: flex;
          align-items: center;
          margin: 10px 0;
          cursor: pointer;
          p {
            color: #57b847;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            margin: 0;
            padding: 0 20px;
            &:first-child {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              border-radius: 47px;
              background: #57b847;
              padding: 10px 40px 10px 20px;
              display: flex;
              text-wrap: nowrap;
            }
          }
        }
        p {
          color: #535353;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          text-align: initial;
        }
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #535353;
          margin: 10px 0 50px;
        }
        button {
          width: 255px;
        }
      }
      &.disabled {
        transform: translate3d(0px, 0, 0) !important;
        justify-content: center;
      }

      .container-sem-resgates {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 273px;
        grid-column: 2;
        position: relative;
        margin-top: 96px;
        margin-left: auto;
        margin-right: auto;
        .voltar {
          position: static;
          margin-top: 64px;
        }
        .container-img {
          margin-bottom: 8px;
        }

        h3 {
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          margin: 0px;
        }
        h4 {
          margin: 0px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .aviso-indisponivel {
      min-height: 523px;
      h5 {
        font-weight: bold;
        font-size: 30px;
        line-height: 118%;
        text-align: center;
        color: #fff;
        position: relative;
        margin-bottom: 10px;
      }
    }

    .personalizado {
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      display: block;

      font-size: 14px;
      font-style: italic;
      line-height: inherit;
      width: 400px;
      margin: 0 auto;
      margin-top: -50px;
    }
  }

  .sac {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 20px 80px -40px rgba(220, 46, 97, 0.6);
    border-radius: 600px;
    cursor: pointer;
    height: 47px;
    padding: 0 20px 0 2px;
    span {
      font-weight: bold;
      font-size: 18px !important;
      line-height: 140%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
    }
    svg {
      fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      rect {
        fill: ${({ backgroundColor }) => backgroundColor || "#8b288c"};
      }
    }
  }

  @media (max-width: 1211px) {
    .overlay-modal {
      height: 100%;
      &::before,
      &::after {
        content: none;
      }
    }

    .container {
      width: 90%;
      .voltar {
        position: static;
        justify-content: center;
        margin-top: 32px;
      }

      .topo-titulo {
        gap: 0px;
        .filtros {
          input,
          button {
            height: 40px;
          }
        }
        .titulo-contador {
          width: 100%;
          p {
            padding-bottom: 12px;
            margin-bottom: 12px;
          }

          .contador-voucher {
            margin-bottom: 18px;
          }
        }
      }
      &.carteira-container {
        width: 90%;
        grid-template-columns: 1fr;
        .container-sem-resgates {
          grid-column: 1;
        }
        .premio {
          .link {
            img {
              width: 15px !important;
            }
            p {
              &:first-child {
                padding: 10px 20px;
                width: 50%;
              }
            }
          }
        }
      }
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
      }

      .premio {
        .voucher-indisponivel-uniclass {
          margin-bottom: 20px;
        }
        &:hover {
          transform: scale(1) !important;
        }
        img {
          width: 250px !important;
          top: -40px;
        }
        .nome-descricao {
          width: 100%;
          h4 {
            font-size: 14px;
            width: 90%;
          }
        }
        button {
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .visualizarCupom {
          img {
            width: auto !important;
          }
        }
      }

      .topo-titulo {
        flex-direction: column;
        h2 {
          width: 100%;
          font-size: 18px;
          line-height: 23px;
          margin-top: ${({ tipo }) => (tipo === "2" ? "-18px" : "18px")};
          margin-bottom: ${({ tipo }) => (tipo === "2" ? "-30px" : "10px")};
          /* font-size: 26px; */
        }
        p {
          line-height: 112%;
        }

        span {
          width: 100%;
          font-size: 14px !important;
        }
        .atencao {
          p {
            font-size: 15px;
          }
        }
        .filtros {
          width: 100%;
          .filtro {
            display: none;
          }
          ${Select} {
            display: none;
          }
        }
      }
      .aviso-indisponivel {
        min-height: 200px;
      }
      .personalizado {
        width: 100%;
        margin-top: 0;
      }
    }
    .sac {
      height: auto;
      span {
        font-size: 11px !important;
      }
      svg {
        height: 26px;
        width: 26px;
      }
    }
  }
`;
