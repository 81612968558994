import { useAcaoContext } from "../../../contexts/acaoContext";
import ImgSchwepps from "../../../assets/img/shweppes.png";
import ImgSair from "../../../assets/img/sair.svg";
import { Container } from "./styles";
import { Link } from "react-router-dom";

export function Header() {
  const { configuracao } = useAcaoContext();

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl = origin_benefit_click_id && origin_benefit_click_id !== ""
    ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
    : baseUrl;

  return (
    <Container>
      <div className="container-img">
        <img src={ImgSchwepps} alt="" />
      </div>
      <button>
        <Link to={linkUrl}>
          <img src={ImgSair} alt="" />
          Sair
        </Link>
      </button>
    </Container>
  );
}
