import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ backgroundColor }) =>
    backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};
  position: relative;
  text-align: center;
  .container {
    min-height: calc(100vh - 84px);
    width: 1203px;
    padding-bottom: 82px;
    position: relative;
    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        display: flex;
        grid-gap: 10px;
        span {
          font-family: "Montserrat-Regular";
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .logos {
      grid-gap: 40px;
      display: flex;
      align-items: baseline;
      margin-bottom: 40px;
      padding-top: 40px;
      img {
        object-fit: contain;
        width: 200px;
        height: 100px;
      }
    }
    .box {
      background: #ffffff;
      border-radius: 20px;
      padding: 47px;
      display: flex;
    }
    .topo-titulo {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h2 {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        text-align: initial;
        color: ${({ backgroundColor }) => backgroundColor || "#6F3E98"};
        position: relative;
        margin-bottom: 10px;
        margin-top: 0;
      }
      p {
        margin-left: auto;
        margin-right: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: initial;
        color: #5e5f5f;
        margin-bottom: 10px;
      }
      .aviso {
        width: 100%;
        height: 84px;
        background: ${({ backgroundColor }) => backgroundColor || "#6F3E98"};
        border-radius: 0px 15px 15px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 127.5%;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
      }
      button {
        margin: 0 auto;
        width: 100%;
      }
      .disabled {
        button {
          pointer-events: none;
          background: #c4c4c4 !important;
          color: #5e5f5f;
        }
      }
    }
    .d-none {
      display: none !important;
    }
  }

  @media (max-width: 812px) {
    .container {
      width: 90%;
      padding-bottom: 0;
      .logos {
        img {
          width: 150px;
        }
      }
      iframe {
        width: auto;
        height: auto;
      }
    }
  }

  @media (max-width: 1211px) {
    .container {
      width: 90%;
      padding-bottom: 0;
      .logos {
        margin-bottom: 0;
        padding-top: 0;
        grid-gap: 10px;
        img {
          width: 109px;
        }
      }

      .box {
        flex-direction: column;
        align-items: center;
        padding: 15px;
        .topo-titulo {
          margin-left: 0;
          h2 {
            font-size: 18px;
            line-height: 22px;
            text-align: center;
          }
          p {
            font-size: 14px;
            line-height: 150%;
            text-align: center;
          }
          .aviso {
            height: 50px;
          }
        }
      }
      iframe {
        width: auto;
        height: auto;
      }

      .topo-titulo {
        h2 {
          width: 100%;
          font-size: 26px;
        }
        p {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
`;
