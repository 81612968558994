import React from 'react';

const GiftBoxIcon = ({ color = "#ffffff", width = 33, height = 37 }) => (
  <svg width={width} height={height} viewBox="0 0 33 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M30.1185 12.8486L26.4916 11.5286C27.8029 10.445 28.3629 8.72634 27.9614 7.09314C27.1725 3.88433 23.2601 2.66622 20.7883 4.85131L18.2842 7.06495L17.7888 3.74507C17.299 0.476577 13.515 -1.09592 10.8529 0.851635C9.52186 1.82541 8.81381 3.50407 9.1278 5.20852L5.70834 3.96397C4.026 3.35152 2.15873 4.22217 1.54628 5.90473L0.0655609 9.97306C-0.138878 10.5348 0.150762 11.156 0.712554 11.3603C0.940407 11.4432 17.3872 17.4294 17.6288 17.5173C16.9443 17.5173 1.95415 17.5173 1.29465 17.5173C0.696872 17.5173 0.21226 18.0019 0.21226 18.5997V33.7529C0.21226 35.5433 1.66892 37 3.45937 37H12.1184H16.4479H25.1069C26.8973 37 28.354 35.5433 28.354 33.7529V21.421L29.1913 21.7258C29.7504 21.9295 30.3732 21.6429 30.5786 21.0789L32.0594 17.0106C32.6716 15.328 31.801 13.461 30.1185 12.8486ZM11.0359 34.8353H3.45929C2.86245 34.8353 2.3769 34.3497 2.3769 33.7529V19.6821H11.0359V34.8353ZM15.3654 34.8353H13.2007V19.6821H15.3654V34.8353ZM22.222 6.47324C23.4776 5.36317 25.4599 5.98588 25.8592 7.61005C26.2851 9.34175 24.6113 10.8433 22.936 10.2345C21.5111 9.71587 20.8433 9.47284 19.4165 8.95347L22.222 6.47324ZM12.131 2.59887C13.4838 1.60927 15.4001 2.41228 15.648 4.06608L16.203 7.78378C15.8035 7.63838 13.0767 6.64581 12.6838 6.50287C11.0084 5.89273 10.6919 3.65149 12.131 2.59887ZM12.6409 13.3982L2.46998 9.69628L3.58056 6.64509C3.78471 6.08424 4.40713 5.79416 4.96791 5.99817C6.10609 6.41239 13.7706 9.20206 14.1216 9.32983L12.6409 13.3982ZM16.7093 14.8791L14.6751 14.1387L16.1559 10.0703L18.1901 10.8108L16.7093 14.8791ZM26.1891 33.7529C26.1891 34.3497 25.7036 34.8353 25.1067 34.8353H17.5302V19.6821H23.5764L26.1892 20.6331V33.7529H26.1891ZM30.025 16.2701L28.9145 19.3213L18.7435 15.6194L20.2243 11.5511L29.3781 14.8827C29.939 15.0869 30.2291 15.7093 30.025 16.2701Z" 
      fill={color} 
    />
  </svg>
);

export default GiftBoxIcon;
