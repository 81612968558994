import { useNavigate } from "react-router-dom";

import img from "../../assets/img/menu.svg";
import img1 from "../../assets/img/calendario-preto.svg";
import img2 from "../../assets/img/sair-preto.svg";

import { Container } from "./styles";

export default function MenuLateral({ isOpen, handleClose, acao, url }) {
  const navigate = useNavigate();

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop") {
      handleClose();
    }
  }

  return (
    <Container
      id="modal-backdrop"
      data-show={isOpen}
      onClick={handleContainerClick}
    >
      <div className="content">
        <div className="titulo">
          <div className="item">
            <img src={img} alt="" onClick={() => handleClose(!isOpen)} />
            <span>MENU</span>
          </div>
          <span>{acao}</span>
        </div>
        <div className="menu">
          <div
            className="item"
            onClick={() => navigate("/" + url + "/carteira")}
          >
            <img src={img1} alt="" />
            <span>Meus Vouchers</span>
          </div>
          <div className="item" onClick={() => navigate("/" + url)}>
            <img src={img2} alt="" />
            <span>Sair</span>
          </div>
        </div>
      </div>
    </Container>
  );
}
