import * as React from "react";

import Slide from "@mui/material/Slide";

import { ModalCenter } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { open, handleClose, children } = props;

  return (
    <ModalCenter
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      className="modal-bottom"
    >
      {children}
    </ModalCenter>
  );
}
