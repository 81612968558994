// React and hooks
import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Services and utilities
import { api } from "../../../services/api";
import Cookies from "universal-cookie";

import ton from "../../../assets/img/logo_ton.png";
import logo_preta from "../../../assets/img/logo_preta.svg";

// Components and styles
import ErrorLoginStone from "../../../components/ErrorLogin/stone";
import { ContainerRedirect } from "../login/styles";

// Context
import { useAcaoContext } from "../../../contexts/acaoContext";

export const LoginWithAuthStone = () => {
  const [responseStatus, setResponseStatus] = useState(null);
  const location = useLocation();
  const keyActionRef = useRef(null);
  const redirectOldFlow = useRef(null);

  const interval = useRef(null);

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const { configuracao } = useAcaoContext();

  const cookies = useMemo(() => new Cookies(), []);

  const saveUserData = useCallback(
    (data) => {
      cookies.remove("yp_user", { path: "/", sameSite: "None", secure: true });
      cookies.set("yp_user", JSON.stringify(data), {
        path: "/",
        sameSite: "None",
        secure: true,
      });
      localStorage.setItem("yp_user", JSON.stringify(data));

      cookies.remove("yp_dadosLogin", {
        path: "/",
        sameSite: "None",
        secure: true,
      });
      cookies.set("yp_dadosLogin", JSON.stringify(data), {
        path: "/",
        sameSite: "None",
        secure: true,
      });
      localStorage.setItem("yp_dadosLogin", JSON.stringify(data));
    },
    [cookies]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search_key = queryParams.get("origin_benefit_click_id");

    if (search_key) {
      cookies.set("yp_paramProduto", search_key, {
        path: "/",
        sameSite: "None",
        secure: true,
      });
      localStorage.setItem("yp_paramProduto", search_key);
    }
  }, [location, cookies]);

  const updateProgressBar = useCallback((finished = false) => {
    if (!interval.current) {
      interval.current = setInterval(() => updateProgressBar(), 300);
    }
  
    if (finished) {
      setProgress(100);
      clearInterval(interval.current);
      interval.current = null;
    } else {
      setProgress((currentVal) => Math.floor(Math.random() * (98 - currentVal) + currentVal));
    }
  }, []);
  
  useEffect(() => {
    const action_key = location.pathname.split("/").pop();
    keyActionRef.current = action_key;
    const action_id = configuracao?.id;
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
  
    if (!token || !action_key) {
      navigate("/ops");
    }
  
    const requestBody = {
      token: token,
      action_id: action_id,
    };
  
    const sendRequest = async () => {
      try {
        updateProgressBar();
  
        const response = await api.post(
          "/client/login",
          requestBody,
          { disableAlert: true }
        );
  
        updateProgressBar(true);
  
        const data = response;
        setResponseStatus(response.status);
        saveUserData(data);
  
        if (data.rewards >= data.max_rewards) {
          navigate("/" + keyActionRef.current + "/finalizacao");
        } else {
          navigate("/" + keyActionRef.current + "/loja");
        }
        
      } catch (error) {
        updateProgressBar(true);
        if (error?.redirect_old_flow === true) {
          redirectOldFlow.current = error.redirect_old_flow;
        }
        setResponseStatus(error.status);
      }
    };
  
    sendRequest();
  }, [location, navigate, configuracao?.id, saveUserData, updateProgressBar]);
  
  if (redirectOldFlow.current) {
    const queryParams = new URLSearchParams(location.search);
    const originBenefitClickId = queryParams.get("origin_benefit_click_id");
    const newUrl =
      "/" +
      keyActionRef.current +
      (originBenefitClickId
        ? "?origin_benefit_click_id=" + originBenefitClickId
        : "");
    window.location.href = newUrl;
  }

  if (
    responseStatus !== 200 &&
    responseStatus !== null &&
    !redirectOldFlow.current
  ) {
    return <ErrorLoginStone configuracao={configuracao} />;
  }

  return (
    <ContainerRedirect>
      <img className="logo_ton" src={ton} alt="" />
      <progress value={progress} max="100">
        {progress} %
      </progress>
      <div className="footer">
        <p>
          Você está sendo direcionado para resgatar o seu benefício no nosso
          parceiro
        </p>
        <img src={logo_preta} alt="" />
      </div>
    </ContainerRedirect>
  );
};
