import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "./styles";
registerLocale("ptBR", ptBR);

function InputCalendar({ change = () => {}, title, placeholder, tipo }) {
  const [startDate, setStartDate] = useState(null);

  return (
    <Container className={tipo}>
      {/* <img src={icone} alt="" /> */}
      <label>{title}</label>
      <DatePicker
        locale="ptBR"
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          change(date);
        }}
      />
    </Container>
  );
}

export default InputCalendar;
