import { useRoutes } from "react-router-dom";
import { useAcaoContext } from "../contexts/acaoContext";

import { publicRoutes, privateRoutes, manutencaoRoutes } from "./routes";
export function Routes() {
  const isAuthenticated = true;
  const { configuracao } = useAcaoContext();

  const estaBloqueadoOuPrivado =
    configuracao?.blocked === 1 ? manutencaoRoutes : privateRoutes;

  const routes = useRoutes([
    ...publicRoutes,
    ...(isAuthenticated ? estaBloqueadoOuPrivado : []),
  ]);

  return routes;
}
