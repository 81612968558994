export function getLocalStore() {
  const config = localStorage.getItem("configuracao");
  if (config) {
    const userJson = JSON.parse(config);
    if (userJson.id) return userJson;
  }

  return {};
}

//retorna só a data
export function formatData(dt) {
  if (!dt) return "";
  const arr = dt.split(" ");
  const data = arr[0].split("-").reverse().join("/");
  // const tempo = arr[1];
  // return data + " - " + tempo;
  return data;
}

export function setLocalStorageShowDigitalWallet() {
  localStorage.setItem(
    "show_digital_wallet",
    JSON.stringify({
      show_digital_wallet: 1,
    })
  );
}

export function getLocalStorageShowDigitalWallet() {
  return JSON.parse(localStorage.getItem("show_digital_wallet"));
}

//retorna data e hora
export function formatDataTime(dt) {
  if (!dt) return "";
  const arr = dt.split(" ");
  const data = arr[0].split("-").reverse().join("/");
  const tempo = arr[1];
  return data + " | " + tempo;
}

export function convertDatePickerTimeToMySQLTime(str) {
  var hours, minutes, seconds;
  var date = new Date(str);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  hours = ("0" + date.getHours()).slice(-2);
  minutes = ("0" + date.getMinutes()).slice(-2);
  seconds = ("0" + date.getSeconds()).slice(-2);

  var mySQLDate = [date.getFullYear(), month, day].join("-");
  var mySQLTime = [hours, minutes, seconds].join(":");
  return [mySQLDate, mySQLTime].join(" ").toString();
}

export function getAllMonthName(mes) {
  switch (mes) {
    case "1":
      return "Janeiro";
    case "2":
      return "Fevereiro";
    case "3":
      return "Março";
    case "4":
      return "Abril";
    case "5":
      return "Maio";
    case "6":
      return "Junho";
    case "7":
      return "Julho";
    case "8":
      return "Agosto";
    case "9":
      return "Setembro";
    case "10":
      return "Outubro";
    case "11":
      return "Novembro";
    case "12":
      return "Dezembro";
    default:
      return "";
  }
}

export function getMesAno(str) {
  var date = new Date(str);
  return [date.getMonth() + 1, date.getFullYear()].join(" ").toString();
}

export function filtroCarteira(data = [], busca = "", status, dataResgate) {
  if (status) {
    if (status === "ativo") {
      data = data.filter((item) => item.expired === false);
    } else if (status === "expirado") {
      data = data.filter((item) => item.expired === true);
    }
  }

  if (dataResgate[0] && dataResgate[1]) {
    const inicio = convertDatePickerTimeToMySQLTime(dataResgate[0]);
    const fim = convertDatePickerTimeToMySQLTime(dataResgate[1]);
    data = data.filter(
      (item) =>
        item.date_assigned.split(" ")[0] >= inicio.split(" ")[0] &&
        item.date_assigned.split(" ")[0] <= fim.split(" ")[0]
    );
  }

  return data.filter((data) => {
    return JSON.stringify(data, [
      "id",
      "name",
      "date_assigned",
      "date_visualized",
      "expires_at",
      "expired",
      "key_one",
      "key_two",
    ])
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroModalCarteira(data = [], values) {
  if (values.tipo) {
    if (values.tipo === "voucher") {
      data = data.filter((item) => item.is_coupon === false);
    } else if (values.tipo === "cupom") {
      data = data.filter((item) => item.is_coupon === true);
    }
  }

  if (values.status) {
    if (values.status === "ativo") {
      data = data.filter((item) => item.expired === false);
    } else if (values.status === "expirado") {
      data = data.filter((item) => item.expired === true);
    }
  }

  if (values.inicio && values.fim) {
    data = data.filter(
      (item) =>
        item.date_assigned.split(" ")[0] >= values.inicio.split(" ")[0] &&
        item.date_assigned.split(" ")[0] <= values.fim.split(" ")[0]
    );
  }

  return data;
}

export function nomeArq(name, size) {
  const extensions = name.split(".").pop();

  return name.substr(0, size) + "..." + extensions;
}

export function dataURLtoFile(dataurl, filename) {
  if (dataurl === "editar-file") return false;

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
