import { KeyCode } from "../../../components/KeyCode";
import { UseModalProvider } from "../../../hooks/modaisBarCode";
import { UseBarCodeProvider } from "../../../hooks/checkBarCode";

export function PageKeyCode() {
  return (
    <UseModalProvider>
      <UseBarCodeProvider>
        <KeyCode />
      </UseBarCodeProvider>
    </UseModalProvider>
  );
}
