import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function HandTalk() {
    const location = useLocation();

    useEffect(() => {
        const handTalkPaths = ['/personnalitemulti',];
        const shouldLoadHandTalk = handTalkPaths.includes(location.pathname);

        if (shouldLoadHandTalk) {
            const script = document.createElement("script");
            script.src = "https://plugin.handtalk.me/web/latest/handtalk.min.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.HT) {
                    new window.HT({
                        token: process.env.REACT_APP_HANDTALK_TOKEN,
                        avatar: "MAYA",
                        addonsEnabled: false,
                    });
                }
            };

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [location]);

    return null;
};
