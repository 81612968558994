import axios from "axios";
import Cookies from "universal-cookie";
import { useAlert } from "../contexts/alert";

const baseUrl = process.env.REACT_APP_BASE_HTTP;

export const api = axios.create({
  baseURL: baseUrl,
});

function getLocalStorage() {
  const response = JSON.parse(localStorage.getItem("yp_dadosLogin"));
  return response;
}

api.interceptors.request.use(function (config) {
  const cookies = new Cookies();

  let dadosLogin = cookies.get("yp_dadosLogin");

  if (!dadosLogin) {
    dadosLogin = getLocalStorage();
  }

  config.headers.authorization = `Bearer ${dadosLogin?.token?.access_token}`;

  return config;
});

function errorHandler(error, config) {
  const { data, status } = error.response;

  if (status === 401 && !(config?.disableAlert)) {
    const actionCode = window.location.pathname.replace("/", "").split("/")[0] ?? "";
    
    useAlert
      .getState()
      .showAlert(
        data?.error ?? "Efetue o login para continuar acessando",
        actionCode
      );
  }

  return Promise.reject(data);
}

api.interceptors.response.use(
  (response) => Promise.resolve(response.data),
  (error) => {
    return errorHandler(error, error.config);
  }
);
