import { Formik, Form, ErrorMessage, Field } from "formik";
import { useBarCode } from "../../../hooks/checkBarCode";
import { initialValues, validation } from "./config";
import { Container } from "./styles";
import { useModal } from "../../../hooks/modaisBarCode";

export function FormKey(props) {
  const { setProcess } = props;
  const { checkQrCode, setState } = useBarCode();
  const { setModal } = useModal();

  return (
    <Container>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          const value = values.key.replace(/\D/g, "").trim();
          setState({});
          setProcess(true);
          setModal(true);

          await checkQrCode(value, () => {
            setProcess(false);
          });
        }}
      >
        {(props) => {
          return (
            <Form>
              <div className={`container-input`}>
                <Field
                  name="key"
                  placeholder="Digite a Chave de Acesso"
                  maxLength={44}
                />
                <ErrorMessage name="key" component="span" />
              </div>

              <div className="mt-auto">
                <button type="submit" disabled={!props.isValid}>
                  Validar
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
