import { Link, useParams } from "react-router-dom";
import img from "../../assets/img/logo-home.svg";

export function RenderLogo(props) {
  const { url } = useParams();
  
  const renderAsLink = props.isClickable !== false;

  if (props?.logo === null && props?.secondary_logo === null) {
    return <img src={img} alt="" />;
  } else {
    return (
      <>
        {props?.logo ? (
          renderAsLink ? (
            <Link to={"/" + url + "/loja"}>
              <img src={props?.logo} alt="" />
            </Link>
          ) : (
            <img src={props?.logo} alt="" />
          )
        ) : (
          <></>
        )}
        {props?.secondary_logo ? (
          <img src={props?.secondary_logo} alt="" />
        ) : (
          <></>
        )}
      </>
    );
  }
}
