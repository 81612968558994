import img from "../../assets/img/logo_ton_verde.svg";

import { ContainerLogoff } from "./styles";

export function Logoff() {
  return (
    <ContainerLogoff>
      <img className="logo_ton" src={img} alt="" />
      <h3>Você foi desconectado :(</h3>
      <p>
        Para acessar seus benefícios novamente,<br></br> basta entrar no App do
        Ton.
      </p>
    </ContainerLogoff>
  );
}
