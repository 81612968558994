import React, { useState } from "react";
import { toast } from "react-toastify";

import { nomeArq } from "../../services/helper";

function InputFile(props) {
  const { form } = props;
  const [arquivo, setArquivo] = useState();

  function handleFile(arq) {
    if (arq) {
      if (arq.size < 1000000) {
        const file = arq;
        const reader = new FileReader();

        reader.onload = function (event) {
          form.setValue("arquivo", "");
          form.setValue("arquivo", event.target.result);
          form.setValue("nomeArquivo", file.name);
        };

        reader.readAsDataURL(file);

        setArquivo(arq.name);
      } else {
        toast.error("Arquivo maior que 1MB");
      }
    }
  }

  return (
    <div className="container-form inputFile">
      <label
        htmlFor="file"
        className={arquivo ? "custom-file nome-arquivo" : "custom-file"}
      >
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg, .pdf, .txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          id="file"
          onChange={(event) => {
            handleFile(event.target.files[0]);
          }}
        />

        {arquivo
          ? arquivo.length > 25
            ? nomeArq(arquivo, 25)
            : arquivo
          : "INSIRA SEU ANEXO (SE DESEJAR)"}
      </label>
    </div>
  );
}

export default InputFile;
