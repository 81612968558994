import { useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";

import { useAcaoContext } from "../../contexts/acaoContext";
import { useLocation } from 'react-router-dom';


export function Acao() {
  
  const { url } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const origin_benefit_click_id = queryParams.get('origin_benefit_click_id');
  
  const navigate = useNavigate();

  const { configuracao, loadingConfiguracao, fetchConfiguracao } =
    useAcaoContext();

  useEffect(() => {
    if (!configuracao && !loadingConfiguracao) {
      fetchConfiguracao({
        chave: url,
        origin_benefit_click_id: origin_benefit_click_id,
        onError: (erro) => navigate("/"),
      });
    }

    // eslint-disable-next-line
  }, []);

  if (!configuracao || loadingConfiguracao) return null;

  return <Outlet />;
}
