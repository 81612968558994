import ImgClick from "../../../../assets/img/click-rosa.svg";
import imgLoader from "../../../../assets/img/loader-yetz-3.svg";

import { ModalSaibaMaisVoucher } from "../../../modal/saibaMaisVoucher";

import { LoadLink } from "./styles";

import { api } from "../../../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";

export function Vouchers(props) {
  const [modalSaibaMaisVoucher, setModalSaibaMaisVoucher] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchLink(url) {
    try {
      setLoading(true);
      const response = await api.post(`/client/action/reward/link/${url}`);
      const [voucher] = response;
      setLoading(false);
      window.location.href = voucher;
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao carregar dados");
    }
  }

  const url = `${props.key_one}/${props.key_two}`;
  return (
    <>
      {loading ? (
        <LoadLink>
          <img className="loader" src={imgLoader} alt="" />
        </LoadLink>
      ) : (
        <div className="container-voucher">
          <h2>
            Você ganhou 01 Voucher <br /> {props.name}
          </h2>

          <div className="container-resultado">
            <div className="container-img">
              <img src={props.image} alt="Comprovante" />
            </div>

            <div className="container-btn">
              <button disabled={loading} onClick={async () => fetchLink(url)}>
                Resgatar
              </button>
              <span
                className="saibaMaisVoucher"
                onClick={() => {
                  setModalSaibaMaisVoucher(true);
                }}
              >
                <img src={ImgClick} alt="" />
                Saiba Mais
              </span>
            </div>
          </div>

          <ModalSaibaMaisVoucher
            isOpen={modalSaibaMaisVoucher}
            // cor={configuracao?.background}
            handleClose={() => setModalSaibaMaisVoucher(false)}
            prod={props}
          />

          {/* <div className="container-resultado update">
  <div className="container-btn">
    <button>Tentar novamente</button>
  </div>
</div> */}
        </div>
      )}
    </>
  );
}
