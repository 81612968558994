import { useState } from "react";
import React, { createContext, useContext } from "react";

const Context = createContext({});

export function UseModalProvider(props) {
  const { children } = props;
  const [modal, setModal] = useState(false);

  function handleClose() {
    setModal(false);
  }

  return (
    <Context.Provider value={{ modal, setModal, handleClose }}>
      {children}
    </Context.Provider>
  );
}

export function useModal() {
  const context = useContext(Context);
  return context;
}
