import { useEffect } from "react";
import { useAcaoContext } from "../../../contexts/acaoContext";
import { toast } from "react-toastify";
import { useHref, useNavigate } from "react-router-dom";
export function LinkVoucher() {
  const { link } = useAcaoContext();
  const navigate = useNavigate();
  const path = useHref().split("/")[1];

  useEffect(() => {
    if (!link) {
      toast.error("Erro ao gerar link de resgate");
      navigate(`/${path}/finalizacao`);
      return;
    }

    if (link) {
      window.location.href = link;
    }
  }, [link, navigate, path]);

  return <h1>opa</h1>;
}
