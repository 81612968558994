import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 66px 25px 0px 25px;
  text-align: center;

  h3 {
    color: #e86972;
    text-align: center;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0px;
    margin-bottom: 100px;
  }

  button {
    border-radius: 100px;
    background: #e86972;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 82px;
    height: 40px;
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:disabled {
      cursor: not-allowed;
      background: #e86972;
      opacity: 0.8;
    }
  }
`;
