import { useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../button";
import CheckBox from "../checkbox";
import InputCalendar from "../inputCalendar";

import {
  convertDatePickerTimeToMySQLTime,
  filtroModalCarteira,
} from "../../services/helper";

import { CodigoSms } from "./styles";

import img1 from "../../assets/img/fecha-preto.svg";

export default function ModalFiltroCarteira(props) {
  const { isOpen, handleClose, cor, carteira, setCarteiraFiltrada } = props;
  // eslint-disable-next-line no-unused-vars
  const [checkTipo, setCheckTipo] = useState(0);
  const [checkStatus, setCheckStatus] = useState(0);

  function handleContainerClick(event) {
    const id = event.target.id;
    if (id === "modal-backdrop") {
      setCheckTipo(0);
      setCheckStatus(0);
      handleClose();
    }
  }

  const { setValue, ...form } = useForm();

  const handleSubmit = form.handleSubmit((values) => {
    const carteiraFiltrada = filtroModalCarteira(carteira, values);
    //  if (carteiraFiltrada.length) {
    handleClose();
    setCarteiraFiltrada(carteiraFiltrada);
    // } else {
    //   toast.error("Sua busca não trouxe resultados");
    // }
  });

  return (
    <CodigoSms
      data-show={isOpen}
      onClick={handleContainerClick}
      onSubmit={handleSubmit}
      cor={cor}
    >
      <div className="content filtroCarteira">
        <button
          className="close-modal"
          onClick={() => {
            handleClose(false);
          }}
        >
          <img src={img1} alt="" width={20} height={20} />
        </button>

        <div className="titulo">
          <h3>Filtro</h3>
        </div>
        <form>
          {/* <div className="filtroSecao">
            <h3>Tipo</h3>

            <div className="dupla">
              <CheckBox
                type="checkbox"
                title="Cupom"
                checked={checkTipo === "cupom" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckTipo("cupom");
                    setValue("tipo", "cupom");
                  } else {
                    setCheckTipo(0);
                    setValue("tipo", null);
                  }
                }}
              />
              <CheckBox
                type="checkbox"
                title="Voucher"
                checked={checkTipo === "voucher" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckTipo("voucher");
                    setValue("tipo", "voucher");
                  } else {
                    setCheckTipo(0);
                    setValue("tipo", null);
                  }
                }}
              />
              <CheckBox
                type="checkbox"
                title="Todos"
                checked={checkTipo === "todos" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckTipo("todos");
                    setValue("tipo", "todos");
                  } else {
                    setCheckTipo(0);
                    setValue("tipo", null);
                  }
                }}
              />
            </div>
          </div> */}

          <div className="filtroSecao">
            <h3>Status</h3>
            <div className="dupla">
              <CheckBox
                type="checkbox"
                title="Ativo"
                checked={checkStatus === "ativo" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckStatus("ativo");
                    setValue("status", "ativo");
                  } else {
                    setCheckStatus(0);
                    setValue("status", null);
                  }
                }}
              />
              <CheckBox
                type="checkbox"
                title="Expirado"
                checked={checkStatus === "expirado" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckStatus("expirado");
                    setValue("status", "expirado");
                  } else {
                    setCheckStatus(0);
                    setValue("status", null);
                  }
                }}
              />
              <CheckBox
                type="checkbox"
                title="Todos"
                checked={checkStatus === "todos" ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckStatus("todos");
                    setValue("status", "todos");
                  } else {
                    setCheckStatus(0);
                    setValue("status", null);
                  }
                }}
              />
            </div>
          </div>

          <div className="filtroSecao">
            <h3>Resgate</h3>
            <div className="dupla">
              <InputCalendar
                title="Início"
                placeholder="dd/mm/aaaa"
                change={(value) => {
                  setValue("inicio", convertDatePickerTimeToMySQLTime(value));
                }}
              />
              <InputCalendar
                title="Fim"
                placeholder="dd/mm/aaaa"
                change={(value) => {
                  setValue("fim", convertDatePickerTimeToMySQLTime(value));
                }}
              />
            </div>
          </div>

          <div className="btns">
            <Button title="Filtrar" tipo="branco" type="submit" />
          </div>
        </form>
      </div>
    </CodigoSms>
  );
}
