export const sendToClarity = () => {

    const userInfo = localStorage.getItem("yp_user") || localStorage.getItem("userInfo");
  
    if (userInfo) {
      let userObject;
      try {
        userObject = JSON.parse(userInfo);
      } catch (error) {
        console.error("Erro ao parsear userInfo do localStorage:", error);
        return;
      }
  
      const referenceId = userObject?.reference_id;
  
      if (referenceId) {
        if (typeof window.clarity === 'function') {
          window.clarity('set', 'user_reference_id', referenceId);
          console.log("Dados enviados para o Clarity:", { user_reference_id: referenceId });
        } else {
          console.error("Clarity não está disponível no window.");
        }
      } else {
        console.error("Referência do usuário não encontrada.");
      }
    } else {
      console.error("Nenhuma informação de usuário encontrada no localStorage.");
    }
  };
  