import { useTab } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "./styles";
export function Tabs(props) {
  const { disabled } = props;
  const { tabs } = useTab();
  const { url } = useParams();

  const navigate = useNavigate();

  return (
    <Container>
      <div className="container-tabs">
        {tabs.map((tab) => {
          return (
            <button
              disabled={!tab.active ? true : disabled}
              onClick={() => {
                if (tab.path) {
                  navigate(`/${url}${tab.path}`);
                }
              }}
            >
              <div className="container-img">
                <img src={tab.img} alt="" />
              </div>

              <p>{tab.title}</p>
            </button>
          );
        })}
      </div>
    </Container>
  );
}
