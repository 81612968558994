import { create } from "zustand";

const useAlert = create((set) => ({
  isOpen: false,
  message: undefined,
  redirectTo: undefined,
  showAlert: (message, redirectTo = undefined) => {
    set(() => ({ message, redirectTo, isOpen: true }));
  },
  closeAlert: () => {
    set(() => ({
      message: undefined,
      redirectTo: undefined,
      isOpen: false,
    }));
  },
}));

export { useAlert };
