import { Container } from "./styles";
import ImgIfoodCard from "../../../assets/img/ifood-card.png";
import ImgCheck from "../../../assets/img/check-rosa.svg";
export function Validado() {
  return (
    <Container>
      <div className="container">
        <div className="container-img">
          <img src={ImgIfoodCard} alt="" />
        </div>

        <h2>iFood • R$10,00</h2>

        <ul>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
          <li>
            <img src={ImgCheck} alt="" />
            <span>Resgate o código para usar no APP iFood.</span>
          </li>
        </ul>

        <button>ok</button>
      </div>
    </Container>
  );
}
