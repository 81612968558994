import styled from "styled-components";

import img2 from "../../../assets/img/seta.svg";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  z-index: 20;
  background: ${({ backgroundColor }) =>
    backgroundColor || "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%)"};

  // CSS layout STONE
  * {
    color: ${({ isStone }) => isStone && "#000"} !important;
  }

  .container {
    min-height: calc(100vh - 84px);
    width: 1124px;
    padding-top: 50px;
    .voltar {
      display: flex;
      grid-gap: 10px;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
    .topo {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .logos {
      display: flex;
      justify-content: center;
      grid-gap: 40px;

      img {
        object-fit: contain;
        width: 200px;
        height: 50px;
        &:first-child {
          border-right: 1px solid #ffffff;
        }
      }
    }
    .conteudo {
      background: #ffffff;
      border-radius: 10px;
      padding: 50px;
      margin-bottom: 80px;
      .item {
        background: #ffffff;
        border: 1px solid #9cabb8;
        border-radius: 15px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        height: 81px;
        overflow: hidden;
        transition: all 0.5s;
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 41px;
          color: ${({ backgroundColor }) => backgroundColor || "#6f3e98"};
          margin: 0;
          padding: 20px;
          height: 81px;
          display: flex;
          align-items: center;
        }
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #5e5f5f;
          padding: 0 20px 20px 20px;
          display: block;
        }
        ol {
          margin: 0;
          margin-bottom: 20px;
          li {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5e5f5f;
          }
        }
        &:before {
          content: url(${img2});
          position: absolute;
          top: 20px;
          right: 20px;
          transition: all 0.5s;
        }
        &.active {
          height: auto;
          transition: all 0.5s;
          &:before {
            transform: rotate(-180deg);
            transition: all 0.5s;
          }
        }
      }
    }
    .faleConosco {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 40px;
      margin-bottom: 80px;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 41px;
        color: #ffffff;
      }
      button {
        background: ${(props) => props.button_color || "#f17e21"};
        border-radius: 44px;
        width: 249px;
        height: 55px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        cursor: pointer;
        span {
          font-weight: 900;
          font-size: 16px;
          line-height: 41px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }
  @media (max-width: 1211px) {
    .container {
      width: 90%;
      padding-top: 80px;
      .logos {
        img {
          width: 150px;
        }
      }
      .topo {
        .voltar {
          position: absolute;
          top: 30px;
          right: 20px;
          span {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .conteudo {
        padding: 18px;
        .item {
          height: 70px;
          p {
            height: 70px;
            font-size: 14px;
            line-height: 19px;
            padding-right: 40px;
          }
        }
      }
      .faleConosco {
        position: relative;
        grid-gap: 10px;
        flex-direction: column;
        .voltar {
          position: absolute;
          top: -60px;
          right: 20px;
          span {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
`;
