import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "./styles";
registerLocale("ptBR", ptBR);

function InputRangeCalendar({ change = () => {}, title, placeholder, tipo }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <Container className={tipo}>
      {/* <img src={icone} alt="" /> */}
      <label>{title}</label>
      <DatePicker
        locale="ptBR"
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        // selected={startDate}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
          change(update);
        }}
      />
    </Container>
  );
}

export default InputRangeCalendar;
