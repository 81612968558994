import { SaibaMais } from "../../components/saibaMais";
import { Container } from "./styles";
import imgFechar from "../../assets/img/fecha-preto.svg";

export function ModalSaibaMais(props) {
  const { isOpen, handleClose } = props;

  return (
    <Container
      id="modal-backdrop"
      data-show={isOpen}
      onClick={() => handleClose()}
    >
      <div className="content passo-a-passo">
        <SaibaMais />
        <button className="close-modal" onClick={() => handleClose()}>
          <img src={imgFechar} alt="" />
        </button>
      </div>
    </Container>
  );
}
