import { io } from "socket.io-client";
import { getSessionData } from "../utils/session";

const socket = io(process.env.REACT_APP_SOCKET_URL || "https://ws.yetzpromo.com.br", {
  autoConnect: false,
});

function getSocket() {
  const dadosLogin = getSessionData();

  const extraHeaders = dadosLogin
    ? {
        Authorization: `Bearer ${dadosLogin.token.access_token}`,
      }
    : undefined;

  return io(process.env.REACT_APP_SOCKET_URL || "https://ws.yetzpromo.com.br", {
    autoConnect: true,
    extraHeaders,
  });
}

export { socket, getSocket };
