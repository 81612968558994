import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(196deg, #e86972 -5.68%, #febf3e 89.16%);
  min-height: calc(100vh - 1px);
  overflow: hidden;
  padding-top: 31px;
  position: relative;
  z-index: 30;
  display: flex;
  flex-direction: column;
  .container-footer {
    margin-top: auto;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  .container-info {
    margin-top: 16px;
    p {
      color: #000;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      button {
        background: transparent;
        border: none;
        font-weight: 900;
        cursor: pointer;
        text-decoration-line: underline;
      }
    }

    .update {
      display: flex;
      align-items: center;
      margin-left: auto;
      width: auto;
      background-color: transparent;
      border: none;

      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 46px;
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      img {
        margin-right: 8px;
      }
    }
  }

  p {
    &.teste-qr-code {
      font-size: 10px;
    }
  }

  .container-rectagle {
    position: absolute;
    top: 304px;
    z-index: -1;
  }

  @media (max-width: 1280px) {
    .container {
      width: 90%;
      margin: 0 auto;
      &.duvida {
        margin-top: auto;
      }
    }

    .container-footer {
      margin-top: 0px;
    }
  }
`;
