import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 58px;
  h2 {
    color: #e86972;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.6px;
    text-transform: uppercase;
  }

  p {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 15px;
    margin-bottom: 32px;
  }

  .containerimg {
    margin-bottom: 31px;
  }

  button {
    border-radius: 100px;
    background: #e86972;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
