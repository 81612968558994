import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: transparent;
  z-index: -1;
  pointer-events: none;
  transition: all 0.5s;

  &[data-show="true"] {
    background: rgba(0, 0, 0, 0.7);
    pointer-events: all;
    z-index: 9999;
    transition: all 0.5s;

    .content {
      right: 0;
      transition: all 0.5s;
    }
  }
  .content {
    z-index: 99999;
    position: absolute;
    right: -5000px;
    width: 518px;
    height: 100%;
    padding: 3rem 2.5rem;
    background-color: #fff;
    border-radius: 10px 0px 0px 10px;
    transition: all 0.5s;
    text-align: left;
    .titulo {
      border-bottom: 1px solid #d4d4d4;
      padding-bottom: 10px;
      margin-bottom: 20px;
      .item {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 10px;
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #5e5f5f;
        }
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #c5c5c5;
      }
    }
    .menu {
      .item {
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #5e5f5f;
        }
      }
    }
  }
  @media (max-width: 1211px) {
    .content {
      width: 70% !important;
    }
  }
`;
