const styles = {
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        transition: "background-color 1s ease-in-out",
    },
    logoWrapper: {
        backgroundColor: "#000D3C",
        padding: "5px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
        position: "relative",
        transform: 'translateX(0)',
        transition: 'transform 1s ease-in-out',
    },
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        opacity: 1,
        transition: "opacity 0.5s ease-in-out",
    },
    logo: {
        width: "45px",
        height: "45px",
    },
    benefitsLabel: {
        position: "absolute",
        zIndex: 1,
        opacity: 0,
        transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
        transform: 'translateX(0)',
    },
    circularProgressWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
    },
    circularProgress: {
        width: "24px",
        height: "24px",
    },
    spin: {
        animation: 'spin 1.5s linear infinite',
        transformOrigin: 'center center',
    },
};

export default styles;
