import styled from "styled-components";
import imgClick from "../../assets/img/seta-branca.svg";

export const Container = styled.div`
  position: relative;
  margin-bottom: 14px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 5px;
  }
  img {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 20px;
  }
  &.filtro {
    margin-bottom: 0;
    position: relative;
    width: 220px;
    input[type="text"] {
      height: 40px;
      width: 100% !important;
      background: none !important;
      border: none !important;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      &::placeholder {
        color: #fff;
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 180px;
      transform: translate(-50%, -50%);
      background-color: #fff;
      -webkit-mask: url(${imgClick}) no-repeat;
      width: 12px;
      height: 7px;
      z-index: 20;
    }
  }
`;
