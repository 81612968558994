import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    color: #fff;

    margin: 0px;
    padding: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .container-img {
    margin: 0 auto;
  }
`;
