import { useLocation } from "react-router-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import { useAcaoContext } from "../../contexts/acaoContext";
import { LoginWithAuth } from "../../pages/acao/LoginWithAuth/index.js";
import { AcessoNegadoItau } from "../../components/AcessoNegadoItau/index.js";
import { LoginWithAuthStone } from "../../pages/acao/LoginWithAuth/stone.js";

export const AuthHandler = () => {
  const location = useLocation();
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const { configuracao, isStone } = useAcaoContext();

  const searchParams = new URLSearchParams(location.search);
  const oldLogin = searchParams.get("old_login") === "true";
  const tokenItau = searchParams.get("auth_token");
  const tokenTon = searchParams.get("token");

  useEffect(() => {
    if (tokenItau) {
      setAuthToken(tokenItau);
    } else {
      if(tokenTon){
        setAuthToken(tokenTon);
      }      
    }
    setLoading(false);
  }, [tokenItau, tokenTon]);

  if (loading) {
    return null;
  }

  if (authToken && !oldLogin) {
    if (isStone) {
      return <LoginWithAuthStone />;
    } else {
      return <LoginWithAuth />;
    }
  }

  const Login = lazy(() =>
    import("../../pages/acao/login").then((module) => ({
      default: module.Login,
    }))
  );

  const exceptions = [
    "ofertasespeciaispersonnalite3",
    "ofertasespeciaispersonnalite",
    "personnalitemulti",
    "uniclassapi",
    "ofertasespeciaisuniclass",
  ];
  
  if (
    
    ["personnalite", "uniclass"].some((key) => configuracao?.key?.includes(key)) &&
    !exceptions.includes(configuracao?.key)
  
  ) {
    if (!authToken && !oldLogin) {
      return <AcessoNegadoItau />;
    }
  }
  
  return (
    <Suspense>
      <Login />
    </Suspense>
  );
};
