const ClickHandIcon = ({ color = "white" }) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.47852 14.5535V5.05342C8.47852 4.60879 8.65515 4.18237 8.96955 3.86797C9.28395 3.55358 9.71037 3.37695 10.155 3.37695C10.5996 3.37695 11.026 3.55358 11.3404 3.86797C11.6548 4.18237 11.8315 4.60879 11.8315 5.05342V13.4358" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.832 12.8766V10.6413C11.832 10.1967 12.0087 9.77026 12.3231 9.45586C12.6375 9.14147 13.0639 8.96484 13.5085 8.96484C13.9532 8.96484 14.3796 9.14147 14.694 9.45586C15.0084 9.77026 15.185 10.1967 15.185 10.6413V13.4355" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.1855 11.7605C15.1855 11.3159 15.3621 10.8894 15.6765 10.575C15.9909 10.2606 16.4173 10.084 16.862 10.084C17.3066 10.084 17.733 10.2606 18.0474 10.575C18.3618 10.8894 18.5384 11.3159 18.5384 11.7605V13.437" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.5371 12.8777C18.5371 12.433 18.7137 12.0066 19.0281 11.6922C19.3425 11.3778 19.7689 11.2012 20.2135 11.2012C20.6582 11.2012 21.0846 11.3778 21.399 11.6922C21.7134 12.0066 21.89 12.433 21.89 12.8777V17.9071C21.89 19.6856 21.28 21.4958 19.9259 22.6489C18.0106 24.2798 14.1456 25.9198 9.99981 23.8108C9.19366 23.4007 8.19049 22.5228 7.57968 21.5953L7.36062 21.26C7.01191 20.7247 5.78809 18.5911 3.68803 14.8581C3.47394 14.4776 3.41676 14.0286 3.52863 13.6066C3.64051 13.1845 3.91262 12.8228 4.28709 12.5983C4.68595 12.3589 5.15335 12.2597 5.61503 12.3164C6.07672 12.3731 6.50622 12.5825 6.83532 12.9112L8.47827 14.5541" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.12545 3.37742L4.00781 2.25977" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.00827 7.84766H2.89062" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.1855 3.37742L16.3031 2.25977" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.3027 6.73047H17.4203" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  export default ClickHandIcon;  