import BarcodeScanner from "../BarcodeScanner";
import { Header } from "../DashboardQRCode/Header";
import ImgBarCode from "../../assets/img/barCode.svg";
import { Container as ContainerDashboard } from "../DashboardQRCode/styles";
import { Container } from "./styles";
import ImgRectangle from "../../assets/img/rectangle-background.png";
import { useState } from "react";

export function BarCode() {
  const [data, setData] = useState("No result");

  const handleBarcodeDetected = (code) => {
    // Handle the detected barcode (e.g., send it to an API)
    setData(code);
  };

  return (
    <ContainerDashboard>
      <Container className="container">
        <Header />

        <div className="container-text-img">
          <img src={ImgBarCode} alt="" />
          <h1>Ler Código de Barras</h1>
        </div>

        <div className="container-nfe">
          <div className="content-nfe">
            <BarcodeScanner onDetected={handleBarcodeDetected} />
          </div>

          <h3>
            Posicione a câmera do seu celular sobre o <br />
            Código de Barras para escanear.
            {JSON.stringify(data, null, 2)}
          </h3>
        </div>
      </Container>

      <div className="container-rectagle">
        <img src={ImgRectangle} alt="" />
      </div>
    </ContainerDashboard>
  );
}
