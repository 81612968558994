import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

import { api } from "../../services/api";

import Footer from "../../components/footer";

import Button from "../../components/button";
import ModalAviso from "../../components/modal/aviso";

import { useAcaoContext } from "../../contexts/acaoContext";

import { Container } from "./styles";

import img from "../../assets/img/logo-home.svg";
import img2 from "../../assets/img/banner.png";

export function Principal() {
  const [active, setActive] = useState(false);
  const [mensagem, setMensagem] = useState();
  const [imagens, setImagens] = useState([]);

  const { loadingConfiguracao, fetchConfiguracao } = useAcaoContext();

  const navigate = useNavigate();

  useEffect(() => {
    api
      .post(`/banners`, {
        action_id: "",
      })
      .then((data) => {
        setImagens(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const defaultValues = {
    chave: "",
    origin_benefit_click_id: "",
  };

  const validationSchema = Yup.object().shape({
    chave: Yup.string().required("Campo Obrigatório"),
  });

  const {
    formState: { errors },
    register,
    ...form
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = form.handleSubmit(
    ({ chave, origin_benefit_click_id }) => {
      fetchConfiguracao({
        chave,
        origin_benefit_click_id,
        onSuccess: (url) => navigate(url),
        onError: (erro) => {
          if (erro && typeof erro === "string") {
            setMensagem(erro);
          } else {
            setMensagem(
              "Algo deu errado! Espere um pouco e recarregue a página."
            );
          }
          setActive(true);
        },
      });
    }
  );

  // function handleopen() {
  //   setModal(!modal);
  // }

  return (
    <Container>
      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />

      <div className="container">
        <div className="container-logo-promo">
          <div className="container-swiper">
            {/* <button onClick={() => handleopen()}>teste</button> */}
            <Swiper
              // install Swiper modules
              modules={[EffectFade, Autoplay]}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop="true"
            >
              {!!imagens?.length ? (
                imagens.map((data, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img src={data.banner} alt="" />
                    </SwiperSlide>
                  );
                })
              ) : (
                <SwiperSlide>
                  <img src={img2} alt="" />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
          <div className="logos">
            <img src={img} alt="" />
          </div>
        </div>

        <div className="form">
          <div className="logos">
            <img src={img} alt="" />
          </div>

          <span>o lugar certo para resgatar prêmios</span>
          <h3>PENSADOS PARA VOCÊ!!</h3>
          <div className="form-login principal">
            <form onSubmit={handleSubmit}>
              <input
                {...register("chave")}
                placeholder="Digite a chave da ação"
                type="text"
              />
              {errors.chave && <span>{errors.chave.message}</span>}

              <Button
                type="submit"
                title={loadingConfiguracao ? "carregando..." : "Acessar"}
              />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
